import { App } from 'antd';
import { DialogProvider } from 'core/providers/dialog-provider';
import { PermissionsProvider } from 'core/providers/permissions-provider';
import { ResponsiveProvider } from 'core/providers/responsive-provider';
import { ComponentPortalProvider } from 'core/providers/component-portal-provider';
import { ThemeProvider } from 'core/providers/theme-provider';
import { UserProvider } from 'core/providers/user-provider';
import AppRouting from 'core/routing/app-routing';

function HearLinkApp() {
  return (
    <App>
      <ComponentPortalProvider>
        <UserProvider>
          <ThemeProvider>
            <DialogProvider>
              <PermissionsProvider>
                <ResponsiveProvider>
                  <AppRouting />
                </ResponsiveProvider>
              </PermissionsProvider>
            </DialogProvider>
          </ThemeProvider>
        </UserProvider>
      </ComponentPortalProvider>
    </App>
  );
}

export default HearLinkApp;
