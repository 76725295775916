import { App } from 'antd';
import { NoahPatientExportsApiService, PatientNoahRecordsApiService } from 'core/api';
import { IPatientDao } from 'core/api/types';
import { useDialog } from 'core/providers/dialog-provider';
import { useUserState } from 'core/providers/user-provider';
import { Unsubscribe } from 'firebase/auth';
import { orderBy, where } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SharedCard from 'shared/card/card';
import { sentryCaptureException } from 'shared/helpers/sentry-helpers';
import SharedTable from 'shared/table/table';
import { INoahPatientRecordDao } from 'core/api/types/noah-patient-record.interface';
import dayjs from 'dayjs';
import SharedElementPermissionGuard from 'shared/permissions/element-permission-guard';
import { Permission } from 'core/constants/permission';
import SharedButton from 'shared/button/button';
import ConfirmActionDialog from 'shared/dialog/confirm-action-dialog';
import NoahLaunchButton from '../patient-information-card/noah-launch-buitton/noah-launch-button';

const PatientNoahRecordList = (patient: IPatientDao) => {
  const { t } = useTranslation();
  const [records, setRecords] = useState<INoahPatientRecordDao[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const { userData } = useUserState();
  const { message } = App.useApp();
  const dialog = useDialog();

  useEffect(() => {
    let unsubscribe: Unsubscribe;
    if (userData) {
      setLoading(true);
      unsubscribe = PatientNoahRecordsApiService.onCollectionSnapshot(
        (snap) => {
          setRecords(snap.docs.map((doc) => doc.data()));
          setLoading(false);
        },
        (error) => {
          message.error(t('patients.patient.noah_records.error'));
          sentryCaptureException(error, 'Patient file fetching Noah records', userData);
        },
        [
          where('organisationUid', '==', userData?.organisationUid),
          where('patientUid', '==', patient.uid),
          orderBy('updated.at', 'desc'),
        ]
      );
    }

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [message, patient.uid, t, userData]);

  const actionsTemplate = (record: INoahPatientRecordDao) => {
    return (
      <div className='w-full flex justify-end space-x-4'>
        <NoahLaunchButton patient={patient} recordUid={record.uid} />
        <SharedElementPermissionGuard requiredPermissions={[[Permission.ORGANISATION_OWNER]]}>
          <SharedButton
            onClick={() =>
              dialog?.openDialog(
                <ConfirmActionDialog
                  action={() => NoahPatientExportsApiService.permDelete(record.uid)}
                  actionButtonProps={{
                    labelKey: 'common.delete',
                    danger: true,
                  }}
                  title={t('patients.patient.noah_records.delete.title')}
                  textContent={t('patients.patient.noah_records.delete.content')}
                  successMessage={t('patients.patient.noah_records.delete.success')}
                  errorMessage={t('patients.patient.noah_records.delete.error')}
                />
              )
            }
            type='button'
            appearance='link'
            labelKey='common.delete'
            danger
          />
        </SharedElementPermissionGuard>
      </div>
    );
  };

  return (
    <SharedCard title={t('navigation.noah_records')}>
      <SharedTable
        loading={loading}
        rows={records.map((record) => ({
          key: record.uid,
          data: record,
        }))}
        columns={[
          {
            labelKey: 'patients.patient.noah_records.table.header.created',
            key: 'createdAt',
            contentTemplateId: 'createdAt',
          },
          {
            key: 'actions',
            contentTemplateId: 'actions',
          },
        ]}
        contentTemplates={[
          {
            template: (record: INoahPatientRecordDao) => (
              <p className='whitespace-pre-wrap body-sm break-words'>
                {t('common.at_by', {
                  at: dayjs(record.created.at.toDate()).format('DD/MM/YYYY, HH:mm'),
                  by: record.created.by.fullName,
                })}
              </p>
            ),
            id: 'createdAt',
          },
          {
            template: actionsTemplate,
            id: 'actions',
          },
        ]}
        headerBackgroundColor='#f8fafc'
      />
    </SharedCard>
  );
};

export default PatientNoahRecordList;
