import { App, Input } from 'antd';
import AddressApiService from 'core/api/address.service';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import SharedButton from 'shared/button/button';
import { createAddressString } from 'shared/helpers/address-string-builder';
import { IAddressInputOutput } from 'shared/interfaces/address.interface';

export interface IManualAddressFormOutput {
  [key: string]: string | undefined;
  addressLine1: string;
  addressLine2?: string;
  city?: string;
  county?: string;
  postcode: string;
}

interface IManualAddressForm {
  onChange?: (value?: IAddressInputOutput) => void;
}

const ManualAddressForm = ({ onChange }: IManualAddressForm) => {
  const [submitting, setSubmitting] = useState(false);
  const { t } = useTranslation();
  const [manualAddressValues, setManualAddressValues] = useState<Partial<IManualAddressFormOutput>>({});
  const [errorKeys, setErrorKeys] = useState<string[]>([]);
  const { message } = App.useApp();

  const saveManualAddress = async () => {
    try {
      setSubmitting(true);
      const errors = fields
        .filter((field) => field.required && !manualAddressValues[field.fieldKey])
        .map((field) => field.fieldKey);
      if (errors.length) {
        setErrorKeys(errors);
        setSubmitting(false);
        message.error(t('common.form.complete_all_fields'));
        return;
      }
      const result = await AddressApiService.getFullAddressFromAddressFormOutputGoogle(manualAddressValues);
      onChange?.({ detail: result, manualInput: true });
    } catch (error) {
      message.warning(t('field.address_search.manual_address.form.error'));
      onChange?.({
        detail: {
          formattedAddress: createAddressString(Object.values(manualAddressValues)),
          postcode: manualAddressValues.postcode!,
        },
        manualInput: true,
      });
    }
  };

  const fields = [
    {
      fieldKey: 'addressLine1',
      placeholder: t('field.address_search.manual_address.form.address_line_1'),
      required: true,
    },
    {
      fieldKey: 'addressLine2',
      placeholder: t('field.address_search.manual_address.form.address_line_2'),
      required: false,
    },
    {
      fieldKey: 'city',
      placeholder: t('field.address_search.manual_address.form.city'),
      required: false,
    },
    {
      fieldKey: 'postcode',
      placeholder: t('field.address_search.manual_address.form.postcode'),
      required: true,
    },
  ];

  return (
    <div className='pb-4 mt-2 flex flex-col space-y-2'>
      {fields.map((field) => (
        <Input
          status={errorKeys.includes(field.fieldKey) ? 'error' : undefined}
          required={field.required}
          key={field.fieldKey}
          placeholder={`${field.placeholder}${field.required ? '*' : ''}`}
          onChange={(e) => {
            setErrorKeys([]);
            setManualAddressValues((prev) => ({ ...prev, [field.fieldKey]: e.currentTarget.value }));
          }}
        />
      ))}
      <SharedButton
        labelKey={'common.save'}
        className='self-end'
        appearance='primary'
        ghost
        onClick={saveManualAddress}
        loading={submitting}
      />
    </div>
  );
};

export default ManualAddressForm;
