import { useTheme } from 'core/providers/theme-provider';
import { useUserState } from 'core/providers/user-provider';

interface IEmailDocumentTemplate {
  emailBody?: string;
  heading?: string;
}

const EmailDocumentTemplate = ({ emailBody, heading }: IEmailDocumentTemplate) => {
  const { primary, logoDark } = useTheme();
  const { organisationData } = useUserState();

  const html = `<!DOCTYPE html>
<html lang="en">
  <head>
    <meta charset="utf-8">
    <meta name="x-apple-disable-message-reformatting">
    <meta http-equiv="x-ua-compatible" content="ie=edge">
    <meta name="viewport" content="width=device-width, initial-scale=1">
    <meta name="format-detection" content="telephone=no, date=no, address=no, email=no">
    <!--[if mso]>
      <noscript>
        <xml>
          <o:OfficeDocumentSettings xmlns:o="urn:schemas-microsoft-com:office:office">
            <o:PixelsPerInch>96</o:PixelsPerInch>
          </o:OfficeDocumentSettings>
        </xml>
      </noscript>
      <style>
        td,
        th,
        div,
        p,
        a,
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          font-family: 'Segoe UI', sans-serif;
          mso-line-height-rule: exactly;
        }
      </style>
    <![endif]-->
    <link rel="preconnect" href="https://fonts.googleapis.com">
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin="">
    <link href="https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap" rel="stylesheet">
    <style>
      @media (max-width: 600px) {
        .sm-w-full {
          width: 100% !important;
        }
        .sm-px-24 {
          padding-left: 24px !important;
          padding-right: 24px !important;
        }
      }
    </style>
  </head>
  <body style="
      margin: 0;
      width: 100%;
      padding: 0;
      word-break: break-word;
      -webkit-font-smoothing: antialiased;
      background-color: #f3f4f6;
    ">
    <div style="display: none">
      {{subject}}&#847; &#847; &#847; &#847; &#847; &#847; &#847; &#847; &#847; &#847; &#847;
      &#847; &#847; &#847; &#847; &#847; &#847; &#847; &#847; &#847; &#847; &#847; &#847; &#847; &#847; &#847; &#847;
      &#847; &#847; &#847; &#847; &#847; &#847; &#847; &#847; &#847; &#847; &#847; &#847; &#847; &#847; &#847; &#847;
      &#847; &#847; &#847; &#847; &#847; &#847; &#847; &#847; &#847; &#847; &#847; &#847; &#847; &zwnj; &#160;&#847;
      &#847; &#847; &#847; &#847; &#847; &#847; &#847; &#847; &#847; &#847; &#847; &#847; &#847; &#847; &#847; &#847;
      &#847; &#847; &#847; &#847; &#847; &#847; &#847; &#847; &#847; &#847; &#847; &#847; &#847; &#847; &#847; &#847;
      &#847; &#847; &#847; &#847; &#847; &#847; &#847; &#847; &#847; &#847; &#847; &#847; &#847; &#847; &#847; &#847;
      &#847; &#847; &#847; &#847; &#847; &#847; &#847; &#847; &zwnj; &#160;&#847; &#847; &#847; &#847; &#847;
    </div>
    <div role="article" lang="en">
      <table style="width: 100%; font-family: ui-sans-serif, system-ui, -apple-system, 'Segoe UI', sans-serif" cellpadding="0" cellspacing="0" role="presentation">
        <tr>
          <td align="center" style="background-color: #f3f4f6">
            <table class="sm-w-full" style="width: 600px" cellpadding="0" cellspacing="0" role="presentation">
              <tr>
                <td align="center" class="sm-px-24">
                  <table style="width: 100%" cellpadding="0" cellspacing="0" role="presentation">
                    <td align="center" style="vertical-align: top; padding-top: 60px;
                    padding-bottom: 40px;" valign="top">
                      <img
                        src="${logoDark}"
                        alt="logo"
                        style="
                          max-width: 300px;
                          max-height: 68px;
                          vertical-align: middle;
                          line-height: 100%;
                          border: 0;
                        "
                      />
                    </td>
                    <tr>
                      <td class="sm-px-24" style="
                          border-radius: 4px;
                          background-color: #ffffff;
                          padding: 54px;
                          text-align: left;
                          font-size: 16px;
                          line-height: 24px;
                          color: #1f2937;
                          box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
                        ">
                        <table cellpadding="0" cellspacing="0" role="presentation">
                          <tr>
                            <td>
                              <p style="margin-top: 0; font-size: 24px; font-weight: 700; color: ${primary.bg}">
                                ${heading ?? 'Email heading'}
                              </p>
                              <p style="margin-top: 32px; white-space: pre-wrap;">${emailBody ?? 'Email body'}</p>
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                    <tr>
                      <td style="padding: 32px; text-align: center; font-size: 12px; color: #9ca3af">
                        <p style="margin: 0.25rem">${organisationData?.name}</p>
                        <p style="margin: 0.25rem">© 2024 All rights reserved</p>
                      </td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
          </td>
        </tr>
      </table>
    </div>
  </body>
</html>
`;

  return <div dangerouslySetInnerHTML={{ __html: html }} />;
};

export default EmailDocumentTemplate;
