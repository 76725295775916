import { App } from 'antd';
import { FormInstance, useForm } from 'antd/es/form/Form';
import { StockApiService } from 'core/api';
import { IPatientSearchResult } from 'core/api/types';
import { IStockDao } from 'core/api/types/stock.interface';
import { EarData, EarOptions } from 'core/constants/ear';
import { StockStatus } from 'core/constants/stock-status';
import { ControlType } from 'core/enums/control-type';
import { useDialog } from 'core/providers/dialog-provider';
import { useAComponentPortal } from 'core/providers/component-portal-provider';
import { useUserState } from 'core/providers/user-provider';
import dayjs, { Dayjs } from 'dayjs';
import { arrayUnion, increment, Timestamp } from 'firebase/firestore';
import { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SharedDialogBase from 'shared/dialog/dialog-base';
import { ISharedField } from 'shared/fields/shared-fields.interface';
import SharedForm from 'shared/form/shared-form';
import { sentryCaptureException } from 'shared/helpers/sentry-helpers';
import { getActionTimestampFromUser } from 'shared/helpers/user-action.helpers';

interface IStockAllocationDialog {
  patient: IPatientSearchResult;
  status: StockStatus;
  stock: IStockDao[];
  tableKey: string;
  setSelectedStock: Dispatch<SetStateAction<IStockDao[]>>;
}

interface IStockAllocationDialogFormOutput {
  [key: string]: Dayjs | 'left' | 'right';
}

const StockAllocationDialog = ({ patient, status, stock, tableKey, setSelectedStock }: IStockAllocationDialog) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const { userData } = useUserState();
  const { message } = App.useApp();
  const dialog = useDialog();
  const table = useAComponentPortal(tableKey);
  const [form] = useForm<IStockAllocationDialogFormOutput>();

  const validateForm = async () => {
    try {
      await form.validateFields();
      return true;
    } catch (error) {
      return false;
    }
  };

  const submit = async () => {
    setLoading(true);
    const isValid = await validateForm();

    if (!isValid) {
      setLoading(false);
      return;
    }
    const values = form.getFieldsValue();
    if (!values) {
      setLoading(false);
      return;
    }
    try {
      const promises = stock.map((stockItem) => {
        const allocatedDate = values[`${stockItem.uid}-allocatedDate`] as Dayjs;
        if (stockItem.type === 'accessory') {
          return StockApiService.update(stockItem.uid, {
            updated: getActionTimestampFromUser(userData),
            quantity: increment(-1),
            'allocation.allocations': arrayUnion({
              at: Timestamp.fromDate(allocatedDate.toDate()),
              to: {
                uid: patient.objectID,
                fullName: patient.fullName,
              },
              status,
            }),
            'allocation.patients': arrayUnion(patient.objectID),
          });
        } else {
          const side = values[`${stockItem.uid}-side`] as 'left' | 'right';
          return StockApiService.update(stockItem.uid, {
            updated: getActionTimestampFromUser(userData),
            status,
            allocated: {
              at: Timestamp.fromDate(allocatedDate.toDate()),
              side,
              to: {
                uid: patient.objectID,
                fullName: patient.fullName,
              },
            },
          });
        }
      });
      await Promise.all(promises);
      dialog?.closeDialog();
      setSelectedStock([]);
      table.exposedFunction();
      message.success(t('stock_management.overview.table.allocated.success'));
    } catch (error) {
      setLoading(false);
      sentryCaptureException(error, 'Allocating stock', userData);
      message.error(t('stock_management.overview.table.allocated.error'));
    }
  };

  const content = (
    <div>
      {stock.map((stockItem) => (
        <StockAllocationForm key={stockItem.uid} stockItem={stockItem} form={form} loading={loading} />
      ))}
    </div>
  );

  return (
    <SharedDialogBase
      title={t('stock_management.stock_allocation.title')}
      customContentTemplate={content}
      customButtons={[
        {
          key: 'close',
          props: {
            onClick: () => dialog?.closeDialog(),
            labelKey: 'common.close',
            type: 'button',
            disabled: loading,
          },
        },
        {
          key: 'submit',
          props: {
            onClick: submit,
            labelKey: 'common.submit',
            appearance: 'primary',
            loading,
          },
        },
      ]}
    />
  );
};

export default StockAllocationDialog;

interface IStockAllocationForm {
  form: FormInstance;
  stockItem: IStockDao;
  loading: boolean;
}

const StockAllocationForm = ({ form, stockItem, loading }: IStockAllocationForm) => {
  const { t } = useTranslation();
  let values = [];
  switch (stockItem.type) {
    case 'hearingAid':
      values = [stockItem.manufacturer, stockItem.model, stockItem.style, stockItem.power, stockItem.colour, stockItem.serialNumber];
      break;
    case 'accessory':
      values = [stockItem.manufacturer, stockItem.accessoryName, stockItem.serialNumber];
      break;
  }

  const formFields: ISharedField[] = [
    {
      fieldKey: `${stockItem.uid}-allocatedDate`,
      control: ControlType.DatePicker,
      label: t('stock_management.stock_allocation.form.allocated_date'),
      required: true,
      fullWidth: true,
    },
  ];

  if (stockItem.type === 'hearingAid') {
    formFields.push({
      fieldKey: `${stockItem.uid}-side`,
      control: ControlType.RadioButton,
      options: EarOptions.map((ear) => {
        const data = EarData[ear];
        return {
          value: data.value,
          label: t(data.translationLabelKey),
        };
      }),
      label: t('stock_management.stock_allocation.form.side'),
      required: true,
    });
  }

  return (
    <div className='p-4 border-b last:border-b-0'>
      <p className='font-semibold mb-4'>{values.filter((value) => Boolean(value)).join(' / ')}</p>
      <SharedForm<IStockAllocationDialogFormOutput>
        submitting={loading}
        formInstance={form}
        name='stock-allocation-form'
        className=''
        buttonsOverride={[]}
        fields={formFields}
        existingValue={{
          [`${stockItem.uid}-allocatedDate`]: dayjs(),
        }}
      />
    </div>
  );
};
