import clsx from 'clsx';
import { IAppointmentDao } from 'core/api/types/appointment.interface';
import { Timestamp } from 'firebase/firestore';
import { useCallback, useEffect, useRef, useState } from 'react';
import SharedCalendarAppointment from './calendar-daily-appointment';
import { ISharedCalendarNewAppointment } from '../calendar';
import { getAppointmentStyle } from 'shared/helpers/appointment-helpers';
import { useTranslation } from 'react-i18next';
import { useUserState } from 'core/providers/user-provider';
import dayjs, { Dayjs } from 'dayjs';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { IClinicDao } from 'core/api/types';
import {
  IDomainCalendarResource,
  IDomainOrganisationDataType,
  OrganisationSettingsSlice,
} from 'modules/organisation-settings/organisation-settings-slice';
import { CalendarMode } from 'core/constants/calendar-mode';
import { IHolidayAndUnavailabilityDao } from 'core/api/types/holiday-and-unavailability.interface';
import SharedCalendarDailyUnavailability from './calendar-daily-unavailability';
import { CalendarTimeframe } from 'core/constants/calendar-timeframe';
import CalendarDailyColumnHeader from './calendar-daily-column-header';
import { useDialog } from 'core/providers/dialog-provider';
import PatientSearchDialog from 'shared/dialog/patient-search-dialog';
import { useSelector } from 'react-redux';

interface ISharedCalendarColumnResourceBase {
  uid: string;
  label: string;
  type: 'person' | 'clinic';
}

export interface ISharedCalendarColumnClinicResource extends ISharedCalendarColumnResourceBase {
  type: 'clinic';
  details: IDomainOrganisationDataType<IClinicDao>;
}

export interface ISharedCalendarColumnPersonResource extends ISharedCalendarColumnResourceBase {
  type: 'person';
  details: IDomainCalendarResource;
}

export type ISharedCalendarColumnResource = ISharedCalendarColumnClinicResource | ISharedCalendarColumnPersonResource;

interface ISharedCalendarColumn {
  resource: ISharedCalendarColumnResource;
  highlightedPerson?: string;
  highlightedClinic?: string;
  existingAppointments: IAppointmentDao[];
  unavailability: IHolidayAndUnavailabilityDao[];
  timeSlots: string[];
  showAppointmentMenu?: boolean;
  timeSlotHeight?: number;
  currentDate: Dayjs;
  resourceHeaderHeight?: number;
  newAppointment?: ISharedCalendarNewAppointment;
  mode: CalendarMode;
  timeframe: CalendarTimeframe;
  zoom: number;
  actualDay: Dayjs;
}

type ICalendarEvent = IAppointmentDao | IHolidayAndUnavailabilityDao;

const SharedCalendarDailyColumn = ({
  resource,
  highlightedPerson,
  highlightedClinic,
  existingAppointments,
  unavailability,
  timeSlots,
  currentDate,
  timeSlotHeight = 60,
  resourceHeaderHeight = 56,
  newAppointment,
  showAppointmentMenu = true,
  mode,
  timeframe,
  zoom,
  actualDay,
}: ISharedCalendarColumn) => {
  const [appGroups, setAppGroups] = useState<ICalendarEvent[][][]>([]);
  const { t } = useTranslation();
  const { organisationData } = useUserState();
  const [searchParams] = useSearchParams();
  const appointmentUid = searchParams.get('appointment');
  const calendarStart = dayjs(organisationData?.calendar.startTime.toDate());
  const columnRef = useRef<HTMLDivElement>(null);
  const dialog = useDialog();
  const navigate = useNavigate();
  const clinicsState = useSelector(OrganisationSettingsSlice.selectClinics);

  const collides = useCallback((a: ICalendarEvent, b: ICalendarEvent) => {
    return a.endDateTime > b.startDateTime && a.startDateTime < b.endDateTime;
  }, []);

  useEffect(() => {
    let g: ICalendarEvent[][][] = [];
    let columns: ICalendarEvent[][] = [];
    let lastAppointmentEnding: Timestamp | undefined;
    [...existingAppointments, ...unavailability]
      .sort(
        (a, b) => a.startDateTime.seconds - b.startDateTime.seconds || a.endDateTime.seconds - b.endDateTime.seconds
      )
      .forEach((appointment) => {
        // Check if a new appointment group needs to be started.
        if (lastAppointmentEnding && appointment.startDateTime >= lastAppointmentEnding) {
          // The appointment is later than any of the appointments in the
          // current group. There is no overlap. Output the
          // current appointments group and start a new one.
          g.push(columns);
          columns = [];
          lastAppointmentEnding = undefined;
        }

        // Try to place the appointment inside an existing column.
        let placed = false;
        columns.some((col) => {
          if (!collides(col[col.length - 1], appointment)) {
            col.push(appointment);
            placed = true;
          }
          return placed;
        });

        // It was not possible to place the appointment (it overlaps
        // with apps in each existing column). Add a new column
        // to the current appointment group with the appointment in it.
        if (!placed) columns.push([appointment]);

        // Remember the last appointment end time of the current group.
        if (!lastAppointmentEnding || appointment.endDateTime > lastAppointmentEnding) {
          lastAppointmentEnding = appointment.endDateTime;
        }
      });
    g.push(columns);
    setAppGroups(g);
  }, [collides, existingAppointments, unavailability]);

  const expand = (a: ICalendarEvent, index: number, cols: ICalendarEvent[][]) => {
    let colSpan = 1;
    cols.slice(index + 1).some((col) => {
      if (col.some((app) => collides(a, app))) return true;
      colSpan += 1;
      return false;
    });
    return colSpan;
  };

  const resourceSelected = resource.uid === highlightedClinic || resource.uid === highlightedPerson;

  const handleMouseDown = (event: React.MouseEvent) => {
    if (event.button === 2 || !showAppointmentMenu || mode === CalendarMode.CLINICS || !columnRef.current) {
      return;
    }
    const rect = columnRef.current.getBoundingClientRect();
    const relativeY = event.clientY - rect.top; // Calculate Y position relative to the div
    const timeMultiplier = relativeY / timeSlotHeight;
    const timeMovement = Math.round((60 * timeMultiplier) / 15) * 15;
    const selectedTime = actualDay.hour(calendarStart.hour()).add(timeMovement, 'minutes');
    let clinic: string | undefined;
    if (resource.type === 'person') {
      const override = resource.details.workingLocationOverrides?.[currentDate.format('YYYY-MM-DD')];
      const todaysLocation = override ? override : resource.details.workingLocations?.[actualDay.day()];
      const matchingClinic = clinicsState?.data.find((clinic) => clinic.uid === todaysLocation);
      if (matchingClinic) {
        clinic = matchingClinic.uid;
      }
    }

    dialog?.openDialog(
      <PatientSearchDialog
        onSelect={(patient) => {
          let navString = `create?patient=${patient.objectID}&date=${selectedTime.format(
            'YYYY-MM-DD'
          )}&time=${selectedTime.format('HH:mm')}&assignee=${resource.uid}`;
          if (clinic) navString = navString.concat(`&clinic=${clinic}`);
          navigate(navString);
        }}
      />
    );
  };

  return (
    <div
      className={clsx(
        'w-full min-w-[200px] flex flex-col border-r last:border-r-0 grow',
        resourceSelected && 'bg-blue-50'
      )}
      style={{ minWidth: timeframe === CalendarTimeframe.WEEK ? `${50 / zoom}px` : '200px' }}
    >
      <CalendarDailyColumnHeader
        resource={resource}
        showAppointmentMenu={showAppointmentMenu}
        resourceAppointments={existingAppointments}
        resourceHeaderHeight={resourceHeaderHeight}
        currentDate={currentDate}
        timeframe={timeframe}
      />

      <div className='grow flex flex-col relative'>
        {newAppointment && resourceSelected && (
          <div
            className='absolute w-full rounded-md shadow-sm text-white z-30 p-2 overflow-y-auto bg-opacity-10'
            style={getAppointmentStyle(
              calendarStart,
              newAppointment.start,
              newAppointment.end,
              false,
              1,
              0,
              timeSlotHeight,
              '#1d4ed8'
            )}
          >
            <p className='font-semibold'>{t('calendar.calendar_column.new_appointment')}</p>
            <p className='body-xs'>
              {newAppointment.start.format('HH:mm')} - {newAppointment.end.format('HH:mm')}
            </p>
          </div>
        )}
        {appGroups.map((cols) =>
          cols.map((col, index) =>
            col
              .filter((event) => event.uid !== appointmentUid)
              .map((event) =>
                'location' in event ? (
                  <SharedCalendarAppointment
                    key={event.uid}
                    appointment={event}
                    timeSlotHeight={timeSlotHeight}
                    widthPercent={expand(event, index, cols) / cols.length}
                    leftPercent={index / cols.length}
                    showAppointmentMenu={showAppointmentMenu}
                    mode={mode}
                  />
                ) : (
                  <SharedCalendarDailyUnavailability
                    key={event.uid}
                    unavailability={event}
                    timeSlotHeight={timeSlotHeight}
                    widthPercent={expand(event, index, cols) / cols.length}
                    leftPercent={index / cols.length}
                    showMenu={showAppointmentMenu}
                  />
                )
              )
          )
        )}
        <div
          className={clsx(
            'absolute h-full w-full',
            showAppointmentMenu && mode !== CalendarMode.CLINICS && 'cursor-pointer'
          )}
          ref={columnRef}
          onMouseDown={handleMouseDown}
        />
        <div className='grow flex flex-col'>
          {timeSlots.map((slot) => (
            <div className='first:border-t-0 border-t grow' style={{ minHeight: `${timeSlotHeight}px` }} key={slot} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default SharedCalendarDailyColumn;
