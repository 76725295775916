import { CollectionID } from 'core/constants/collection-id';
import { Permission } from 'core/constants/permission';
import { useUserState } from 'core/providers/user-provider';
import { where } from 'firebase/firestore';
import { useTranslation } from 'react-i18next';
import SharedButton from 'shared/button/button';
import SharedCard from 'shared/card/card';
import SharedPageHeader from 'shared/page-header/page-header';
import SharedElementPermissionGuard from 'shared/permissions/element-permission-guard';
import SharedPaginatedTable from 'shared/table/paginated-table';
import { ISharedTableColumn, ISharedTableCustomTemplate } from 'shared/table/table.interface';
import AddEditLeadTypeDialog from './add-edit-lead-type-dialog';
import { useDialog } from 'core/providers/dialog-provider';
import { ILeadTypeDao } from 'core/api/types';
import DeleteLeadTypeDialog from './delete-lead-type-dialog';
import clsx from 'clsx';

const tableColumns: ISharedTableColumn[] = [
  {
    labelKey: 'lead_types.lead_types_list.table.header.name',
    key: 'name',
  },
  {
    labelKey: 'lead_types.lead_types_list.table.header.sub_types',
    key: 'subTypes',
    contentTemplateId: 'subTypes',
  },
  { key: 'action', contentTemplateId: 'actions' },
];

const LeadTypesList = () => {
  const { t } = useTranslation();
  const { userData } = useUserState();
  const dialog = useDialog();
  const tableKey = 'lead_types.lead_types_list.table';

  const headerActions = [
    {
      element: (
        <SharedElementPermissionGuard
          requiredPermissions={[[Permission.APPOINTMENT_TYPES_CREATE], [Permission.ORGANISATION_OWNER]]}
        >
          <SharedButton
            labelKey='lead_types.lead_types_list.header.add_lead_type'
            appearance='primary'
            onClick={() => dialog?.openDialog(<AddEditLeadTypeDialog tableKey={tableKey} />)}
          />
        </SharedElementPermissionGuard>
      ),
      key: 'addAppointmentType',
    },
  ];

  const actionTemplate = (leadType: ILeadTypeDao) => {
    return (
      <div className='w-full flex justify-end space-x-4'>
        <SharedElementPermissionGuard
          requiredPermissions={[[Permission.CLINICS_UPDATE], [Permission.ORGANISATION_OWNER]]}
        >
          <SharedButton
            onClick={() => dialog?.openDialog(<AddEditLeadTypeDialog existing={leadType} tableKey={tableKey} />)}
            type='button'
            appearance='link'
            labelKey='common.edit'
            primaryOverride
          />
        </SharedElementPermissionGuard>
        <SharedElementPermissionGuard
          requiredPermissions={[[Permission.CLINICS_DELETE], [Permission.ORGANISATION_OWNER]]}
        >
          <SharedButton
            onClick={() => dialog?.openDialog(<DeleteLeadTypeDialog leadType={leadType} tableKey={tableKey} />)}
            type='button'
            appearance='link'
            labelKey='common.delete'
            danger
          />
        </SharedElementPermissionGuard>
      </div>
    );
  };

  const contentTemplates: ISharedTableCustomTemplate[] = [
    {
      template: actionTemplate,
      id: 'actions',
    },
    {
      template: (leadType: ILeadTypeDao) => {
        const subTypes = leadType.subTypes?.filter((s) => !s.deleted).map((s) => s.name) ?? [];
        return (
          <p className={clsx(subTypes?.length > 0 ? '' : 'text-gray-300')}>
            {subTypes?.length > 0 ? subTypes.join(', ') : t('lead_types.lead_types_list.table.sub_types.none_added')}
          </p>
        );
      },
      id: 'subTypes',
    },
  ];

  return (
    <>
      <SharedPageHeader title={t('navigation.lead_types')} actions={headerActions} />
      <SharedCard>
        {userData && (
          <SharedPaginatedTable
            collectionId={CollectionID.LEAD_TYPES}
            queryConstraints={[
              where('organisationUid', '==', userData?.organisationUid),
              where('deleted', '==', false),
            ]}
            queryOrder={['updated.at', 'desc']}
            tableConfig={{
              columns: tableColumns,
              contentTemplates: contentTemplates,
            }}
            errorMessageKey='lead_types.lead_types_list.table.error.description'
            tableKey={tableKey}
          />
        )}
      </SharedCard>
    </>
  );
};

export default LeadTypesList;
