import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SharedButton from 'shared/button/button';
import { Drawer, Segmented } from 'antd';
import { Settings } from 'react-feather';
import FormItemMimic from 'shared/form/form-item-mimic';
import { CalendarMode, CalendarModeData, CalendarModeOptions } from 'core/constants/calendar-mode';
import { CalendarTimeframe, CalendarTimeframeData, CalendarTimeframeOptions } from 'core/constants/calendar-timeframe';
import { useSearchParams } from 'react-router-dom';

const CalendarSettingsDrawer = () => {
  const { t } = useTranslation();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [showWeeklyViewToggle, setShowWeeklyViewToggle] = useState<boolean>(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const mode = searchParams.get('mode');
  const timeframe = searchParams.get('timeframe');

  const handleToggleChange = useCallback(
    (key: string, value: string | null) => {
      setSearchParams((prev) => {
        if (!value) {
          prev.delete(key);
        } else {
          prev.set(key, value);
        }
        return prev;
      });
    },
    [setSearchParams]
  );

  useEffect(() => {
    if (mode === CalendarMode.CLINICS) {
      setShowWeeklyViewToggle(false);
      handleToggleChange('timeframe', CalendarTimeframe.DAY);
    } else {
      setShowWeeklyViewToggle(true);
    }
  }, [mode, t, handleToggleChange]);

  return (
    <>
      <SharedButton appearance='default' icon={<Settings size={20} />} onClick={() => setOpenDrawer(true)} />
      <Drawer title={t('calendar.settings_drawer.title')} onClose={() => setOpenDrawer(false)} open={openDrawer}>
        <FormItemMimic label={t('calendar.settings_drawer.mode')}>
          <Segmented
            value={mode}
            options={CalendarModeOptions.map((mode) => {
              const data = CalendarModeData[mode];
              return {
                value: data.value,
                label: t(data.translationLabelKey),
              };
            })}
            onChange={(value) => handleToggleChange('mode', value)}
          />
        </FormItemMimic>
        {showWeeklyViewToggle && (
          <FormItemMimic label={t('calendar.settings_drawer.timeframe')}>
            <Segmented
              value={timeframe}
              options={CalendarTimeframeOptions.map((mode) => {
                const data = CalendarTimeframeData[mode];
                return {
                  value: data.value,
                  label: t(data.translationLabelKey),
                };
              })}
              onChange={(value) => handleToggleChange('timeframe', value)}
            />
          </FormItemMimic>
        )}
      </Drawer>
    </>
  );
};

export default CalendarSettingsDrawer;
