import { createContext, useCallback, useContext, useState } from 'react';

interface IComponentPortal {
  components: IComponentPortalComponentsState;
  register: (componentData: IComponentPortalComponentState) => void;
  deregister: (componentId: string) => void;
}

interface IComponentPortalComponentState {
  key: string;
  exposedFunction: () => Promise<void>;
  deregister?: () => void;
}

interface IComponentPortalComponentsState {
  [key: string]: IComponentPortalComponentState;
}

export const ComponentPortal = createContext<IComponentPortal>({
  components: {},
  register: () => {},
  deregister: () => {},
});

export const useComponentPortalState = () => {
  const state = useContext(ComponentPortal);
  return state;
};

export const useAComponentPortal = (id: string) => {
  const state = useContext(ComponentPortal);
  return state.components[id];
};

export const useMultipleComponents = (ids: string[]) => {
  const state = useContext(ComponentPortal);
  return ids.map((id) => state.components[id]);
};

export const ComponentPortalProvider = ({ children }: any) => {
  const [components, setComponents] = useState<IComponentPortalComponentsState>({});

  const register = useCallback((componentData: IComponentPortalComponentState) => {
    return setComponents((prevState) => ({ ...prevState, [componentData.key]: componentData }));
  }, []);

  const deregister = useCallback((tableId: string) => {
    return setComponents((prevState) => {
      const newState = { ...prevState };
      delete newState[tableId];
      return newState;
    });
  }, []);

  return <ComponentPortal.Provider value={{ components, register, deregister }}>{children}</ComponentPortal.Provider>;
};
