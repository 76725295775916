import { FormInstance } from 'antd';
import { ControlType } from 'core/enums/control-type';
import { OrganisationSettingsSlice } from 'modules/organisation-settings/organisation-settings-slice';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import SharedCard from 'shared/card/card';
import FormItemMimic from 'shared/form/form-item-mimic';
import SharedForm from 'shared/form/shared-form';
import { ISharedField } from 'shared/fields/shared-fields.interface';
import { InputType } from 'core/enums/input-type';
import { IOrderDao } from 'core/api/types/order.interface';
import SignatureCanvas from 'react-signature-canvas';
import { useEffect, useState } from 'react';
import { storage } from 'core/config/firebase';
import { getBlob, ref } from 'firebase/storage';

interface IAddEditOrderCustomerDeclaration {
  order?: IOrderDao;
  form: FormInstance;
}

export interface IAddEditOrderCustomerDeclarationFormOutput {
  customerVerification?: string | SignatureCanvas | null;
}

const AddEditOrderCustomerDeclaration = ({ order, form }: IAddEditOrderCustomerDeclaration) => {
  const orderFormSettings = useSelector(OrganisationSettingsSlice.selectOrderFormSettings);
  const { disabledFields, defaultFields, optionalFields } = orderFormSettings?.data ?? {};
  const { customerDeclaration, customerVerification, cancelationPolicy } = defaultFields ?? {};
  const { t } = useTranslation();
  const [signatureImage, setSignatureImage] = useState<string>();
  const isFieldEnabled = (fieldKey: string) => !disabledFields?.includes(fieldKey);
  const isFieldMandatory = (fieldKey: string) => !optionalFields?.includes(fieldKey);
  const formFields: ISharedField[] = [
    {
      fieldKey: 'customerVerification',
      control: ControlType.TextField,
      type: InputType.Text,
      label: t('orders.add_edit_order.declaration.form.patient_signature'),
      required: isFieldMandatory('customerVerification'),
      hidden: customerVerification !== 'manual',
    },
    {
      fieldKey: 'customerVerification',
      control: ControlType.Signature,
      label: t('orders.add_edit_order.declaration.form.patient_signature'),
      required: isFieldMandatory('customerVerification'),
      hidden: customerVerification !== 'signature',
      dataUrl: signatureImage,
      onClear: () => setSignatureImage(undefined),
      disabled: signatureImage !== undefined,
    },
  ];

  useEffect(() => {
    if (order?.patientVerification?.method === 'signature' && order?.patientVerification?.value) {
      const getImage = async (imageUrl: string) => {
        const blob = await getBlob(ref(storage, imageUrl));
        const reader = new FileReader();
        reader.onloadend = () => {
          const result = reader.result as string;
          setSignatureImage(result);
        };
        reader.readAsDataURL(blob);
      };
      getImage(order.patientVerification.value);
    }
  }, [order]);

  return (
    <SharedCard title={t('orders.add_edit_order.patient_declaration')} innerClassName='p-4'>
      {isFieldEnabled('customerDeclaration') && <p className='mb-4 whitespace-pre-wrap'>{customerDeclaration}</p>}
      {isFieldEnabled('customerVerification') && (
        <SharedForm
          fields={formFields}
          formInstance={form}
          name='add_edit_order.patient_declaration'
          className=''
          buttonsOverride={[]}
          existingValue={{ customerVerification: order?.patientVerification?.value }}
        />
      )}
      {isFieldEnabled('cancelationPolicy') && (
        <FormItemMimic className='mt-4' label={t('orders.add_edit_order.declaration.form.cancelation_policy')}>
          <p className='whitespace-pre-wrap'>{cancelationPolicy}</p>
        </FormItemMimic>
      )}
    </SharedCard>
  );
};

export default AddEditOrderCustomerDeclaration;
