import clsx from 'clsx';
import { useTheme } from 'core/providers/theme-provider';
import { useUserState } from 'core/providers/user-provider';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { getAppointmentStyle } from 'shared/helpers/appointment-helpers';
import { IHolidayAndUnavailabilityDao } from 'core/api/types/holiday-and-unavailability.interface';
import { UnavailabilityTypeData } from 'core/constants/unavailability-type';
import UnavailabilityMenu from '../unavailability-menu';

interface ISharedCalendarDailyUnavailability {
  unavailability: IHolidayAndUnavailabilityDao;
  timeSlotHeight: number;
  widthPercent: number;
  leftPercent: number;
  showMenu?: boolean;
}

const SharedCalendarDailyUnavailability = ({
  unavailability,
  timeSlotHeight,
  widthPercent,
  leftPercent,
  showMenu,
}: ISharedCalendarDailyUnavailability) => {
  const { primary } = useTheme();
  const { t } = useTranslation();
  const { organisationData } = useUserState();
  const start = dayjs(organisationData?.calendar.startTime.toDate());

  const typeData = UnavailabilityTypeData[unavailability.type];

  const label = () => {
    let parts = [t(typeData.translationLabelKey)];

    if (unavailability.description) {
      if (unavailability.type === 'unavailability') {
        parts = [unavailability.description];
      } else {
        parts.push(`(${unavailability.description})`);
      }
    }
    return parts.join(' ');
  };

  return (
    <div
      className={clsx(
        'group absolute w-full rounded-md shadow-sm z-10 overflow-y-auto hide-scrollbar flex justify-between items-start'
      )}
      style={getAppointmentStyle(
        start,
        dayjs(unavailability.startDateTime.toDate()),
        dayjs(unavailability.endDateTime.toDate()),
        false,
        widthPercent,
        leftPercent,
        timeSlotHeight,
        `repeating-linear-gradient(45deg, ${primary.bg}26, ${primary.bg}26 10px, ${primary.bg}3F 10px, ${primary.bg}3F 20px)`
      )}
    >
      <div className='relative w-full'>
        {showMenu && (
          <UnavailabilityMenu
            unavailability={unavailability}
            className='absolute top-0 right-0 md:hidden md:group-hover:block z-10'
          />
        )}
        <div className='p-2'>
          <p className='body-sm text-slate-500 font-semibold'>{label()}</p>
        </div>
      </div>
    </div>
  );
};

export default SharedCalendarDailyUnavailability;
