import { App, Form } from 'antd';
import { AppointmentsApiService, OrderApiService, PatientApiService } from 'core/api';
import { IPatientDao } from 'core/api/types';
import { IAppointmentDao } from 'core/api/types/appointment.interface';
import { useUserState } from 'core/providers/user-provider';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { sentryCaptureException } from 'shared/helpers/sentry-helpers';
import SharedPageHeader from 'shared/page-header/page-header';
import { useSelector } from 'react-redux';
import { OrganisationSettingsSlice } from 'modules/organisation-settings/organisation-settings-slice';
import AddEditOrderDetails, { IAddEditOrderDetailsFormOutput } from './add-edit-order-details';
import SkeletonElement from 'shared/skeleton/skeleton-element';
import SharedButton from 'shared/button/button';
import AddEditOrderAccessoriesServices from './add-edit-order-accessories-services';
import AddEditOrderCosting, { IAddEditOrderCostingFormOutput } from './add-edit-order-costing';
import { useWatch } from 'antd/es/form/Form';
import AddEditOrderHearingAids from './add-edit-order-hearing-aids';
import AddEditOrderCustomerDeclaration, {
  IAddEditOrderCustomerDeclarationFormOutput,
} from './add-edit-order-customer-declaration';
import SharedCard from 'shared/card/card';
import SharedForm from 'shared/form/shared-form';
import { ControlType } from 'core/enums/control-type';
import { InputType } from 'core/enums/input-type';
import { v4 as uuidv4 } from 'uuid';
import { IAddEditOrderHearingAidsEarFormOutput } from './add-edit-order-hearing-aids-ear';
import { getActionTimestampFromUser } from 'shared/helpers/user-action.helpers';
import { IOrderDao, IOrderHearingAidDao } from 'core/api/types/order.interface';
import { deleteField, Timestamp } from 'firebase/firestore';
import { ref, uploadString } from 'firebase/storage';
import { storage } from 'core/config/firebase';
import ReactSignatureCanvas from 'react-signature-canvas';

export interface IOrderAccessoryOrService {
  itemKey: string;
  type: string;
  name: string;
  price: number;
  manufacturer?: string;
}

const AddEditOrder = () => {
  //Hooks
  const { t } = useTranslation();
  const { message } = App.useApp();
  const { userData, organisationData } = useUserState();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  //Params
  const orderUid = searchParams.get('order');
  const patientUid = searchParams.get('patient');
  const appointmentUid = searchParams.get('appointment');

  //State
  const [fetchingData, setFetchingData] = useState(true);
  const [order, setOrder] = useState<IOrderDao>();
  const [patient, setPatient] = useState<IPatientDao>();
  const [appointment, setAppointment] = useState<IAppointmentDao>();
  const [submitting, setSubmitting] = useState(false);
  const [accessoriesAndServices, setAccessoriesAndServices] = useState<IOrderAccessoryOrService[]>([]);

  //Selectors
  const orderFormSettings = useSelector(OrganisationSettingsSlice.selectOrderFormSettings);

  //Forms
  const [orderDetailsForm] = Form.useForm<IAddEditOrderDetailsFormOutput>();
  const [leftEarProductForm] = Form.useForm<IAddEditOrderHearingAidsEarFormOutput>();
  const [rightEarProductForm] = Form.useForm<IAddEditOrderHearingAidsEarFormOutput>();
  const [costingForm] = Form.useForm<IAddEditOrderCostingFormOutput>();
  const [declarationForm] = Form.useForm<IAddEditOrderCustomerDeclarationFormOutput>();
  const [confirmationForm] = Form.useForm();

  //Watchers
  const leftPrice = useWatch('price', leftEarProductForm);
  const rightPrice = useWatch('price', rightEarProductForm);
  const sendConfirmationEmail = useWatch('sendConfirmationEmail', confirmationForm);

  //Data
  const patientData = appointment ? appointment.patient : patient;
  const accessoriesAndServicesPrice = accessoriesAndServices.reduce((acc, item) => acc + item.price, 0);
  const grossPrice = (leftPrice ?? 0) + (rightPrice ?? 0) + accessoriesAndServicesPrice;
  const vatAmount =
    organisationData?.finance.vatEnabled && organisationData?.finance.vatStandardRate
      ? Math.round((grossPrice * (organisationData?.finance.vatStandardRate / 100) + Number.EPSILON) * 100) / 100
      : 0;
  const disabledFields = orderFormSettings?.data?.disabledFields ?? [];

  const fetchOrderData = useCallback(
    async (orderUid: string) => {
      try {
        const snap = await OrderApiService.get(orderUid);
        if (!snap.exists()) {
          throw new Error('Order not found');
        } else {
          setOrder(snap.data());
          setAccessoriesAndServices(snap.data().accessoriesAndServices);
          setPatient(snap.data().patient);
        }
      } catch (error) {
        message.error(t('orders.add_edit_order.error_fetching_order'));
        sentryCaptureException(error, 'Add edit order, fetching order', userData);
      }
    },
    [message, t, userData]
  );

  const fetchAppointmentData = useCallback(
    async (appointmentUid: string) => {
      try {
        const snap = await AppointmentsApiService.get(appointmentUid);
        if (!snap.exists()) {
          throw new Error('Appointment not found');
        } else {
          setAppointment(snap.data());
          setPatient(snap.data().patient);
        }
      } catch (error) {
        message.error(t('orders.add_edit_order.error_fetching_appointment'));
        sentryCaptureException(error, 'Add edit order, fetching appointment', userData);
      }
    },
    [message, t, userData]
  );

  const fetchPatientData = useCallback(
    async (patientUid: string) => {
      try {
        const snap = await PatientApiService.get(patientUid);
        if (!snap.exists()) {
          throw new Error('Patient not found');
        } else {
          setPatient(snap.data());
        }
      } catch (error) {
        message.error(t('orders.add_edit_order.error_fetching_patient'));
        sentryCaptureException(error, 'Add edit order, fetching patient', userData);
      }
    },
    [message, t, userData]
  );

  useEffect(() => {
    if (orderUid) {
      fetchOrderData(orderUid);
    }

    if (!orderUid && appointmentUid) {
      fetchAppointmentData(appointmentUid);
    }

    if (!orderUid && !appointmentUid && patientUid) {
      fetchPatientData(patientUid);
    }

    setFetchingData(false);
  }, [fetchOrderData, fetchPatientData, fetchAppointmentData, orderUid, patientUid, appointmentUid]);

  const formatHearingAidData = (formData: IAddEditOrderHearingAidsEarFormOutput): IOrderHearingAidDao => {
    const { receiver, gain, earbudSize, earbudType, ...rest } = formData;
    return {
      ...rest,
      ...(receiver && { receiver }),
      ...(gain && { gain }),
      ...(earbudSize && { earbudSize }),
      ...(earbudType && { earbudType }),
    };
  };

  const submit = async () => {
    try {
      setSubmitting(true);
      const forms = [orderDetailsForm, leftEarProductForm, rightEarProductForm, costingForm, declarationForm];
      const { audiologist, orderDate, orderNumber, additionalInformation } = orderDetailsForm.getFieldsValue();
      const { discount, depositAmount, depositPaymentMethod, depositVerification, balancePaymentMethod } =
        costingForm.getFieldsValue();
      const { sendConfirmationEmail, emailAddress } = confirmationForm.getFieldsValue();
      const { customerVerification } = declarationForm.getFieldsValue();

      const validationPromises = forms.map((form) => {
        return form.validateFields();
      });

      await Promise.all(validationPromises);

      const audiologistVerificationMethod = orderFormSettings?.data?.defaultFields?.audiologistVerification ?? 'manual';
      const customerVerificationMethod = orderFormSettings?.data?.defaultFields?.customerVerification ?? 'manual';
      const depositFields = ['deposit', 'audiologistVerification'];
      const depositVerificationEnabled = !depositFields.some((key) => disabledFields.includes(key));
      const customerVerificationEnabled = !disabledFields.includes('customerVerification');

      if (!userData?.organisationUid || !patientData) {
        throw new Error('Missing data');
      }

      const orderUid = order ? order.uid : uuidv4();

      const signatureUploads = [];
      if (
        depositVerificationEnabled &&
        depositVerification instanceof ReactSignatureCanvas &&
        depositVerification?.isEmpty() === false
      ) {
        signatureUploads.push({
          ref: depositVerification,
          path: `signatures/${userData.organisationUid}/${orderUid}/audiologist.png`,
          key: 'audiologistVerification',
        });
      }
      if (
        customerVerificationEnabled &&
        customerVerification instanceof ReactSignatureCanvas &&
        customerVerification?.isEmpty() === false
      ) {
        signatureUploads.push({
          ref: customerVerification,
          path: `signatures/${userData.organisationUid}/${orderUid}/patient.png`,
          key: 'customerVerification',
        });
      }

      await Promise.all(
        signatureUploads.map(async (sig) =>
          uploadString(ref(storage, sig.path), sig.ref!.getTrimmedCanvas().toDataURL(), 'data_url')
        )
      );

      const userTimestamp = getActionTimestampFromUser(userData);
      const depositVerificationValue =
        audiologistVerificationMethod === 'manual'
          ? (depositVerification as string | undefined)
          : signatureUploads.find((sig) => sig.key === 'audiologistVerification')?.path;
      const customerVerificationValue =
        customerVerificationMethod === 'manual'
          ? (customerVerification as string | undefined)
          : signatureUploads.find((sig) => sig.key === 'customerVerification')?.path;

      const basePayload = {
        orderDate: Timestamp.fromDate(orderDate.toDate()),
        ...(orderNumber && { orderNumber }),
        audiologist,
        patient: patientData!,
        accessoriesAndServices: accessoriesAndServices.map((item) => {
          return {
            itemKey: item.itemKey,
            type: item.type,
            name: item.name,
            price: item.price,
            uid: uuidv4(),
            ...(item.manufacturer && { manufacturer: item.manufacturer }),
          };
        }),
        hearingAids: {
          ...(leftPrice && {
            left: formatHearingAidData(leftEarProductForm.getFieldsValue()),
          }),
          ...(rightPrice && {
            right: formatHearingAidData(rightEarProductForm.getFieldsValue()),
          }),
        },
        ...(appointmentUid &&
          appointment && {
            linkedAppointment: {
              uid: appointmentUid,
              typeUid: appointment.type,
              clinic: appointment.clinic,
            },
          }),
        ...(additionalInformation && { additionalInformation }),
        grossPrice,
        ...(discount !== undefined && { discount }),
        ...(balancePaymentMethod && { balancePaymentMethod }),
        ...(depositAmount !== undefined && {
          deposit: {
            amount: depositAmount,
            ...(depositPaymentMethod && { method: depositPaymentMethod }),
          },
        }),
        confirmationEmail: {
          send: sendConfirmationEmail,
          ...(emailAddress && { emailAddress }),
        },
        updated: userTimestamp,
        cancelled: false,
        vat: {
          amount: vatAmount,
          enabled: organisationData?.finance.vatEnabled ?? false,
        },
        finalPrice: grossPrice + vatAmount - (discount ?? 0),
      };

      const createPayload = {
        ...basePayload,
        organisationUid: userData.organisationUid,
        uid: orderUid,
        created: userTimestamp,
      };

      try {
        order
          ? await OrderApiService.update(order.uid, {
              ...basePayload,
              audiologistVerification: depositVerificationEnabled
                ? {
                    method: audiologistVerificationMethod,
                    ...(depositVerificationValue && { value: depositVerificationValue }),
                  }
                : deleteField(),
              patientVerification: customerVerificationEnabled
                ? {
                    method: customerVerificationMethod,
                    ...(customerVerificationValue && { value: customerVerificationValue }),
                  }
                : deleteField(),
            })
          : await OrderApiService.set({
              ...createPayload,
              ...(depositVerificationEnabled && {
                audiologistVerification: {
                  method: audiologistVerificationMethod,
                  ...(depositVerificationValue && { value: depositVerificationValue }),
                },
              }),
              ...(customerVerificationEnabled && {
                patientVerification: {
                  method: customerVerificationMethod,
                  ...(customerVerificationValue && { value: customerVerificationValue }),
                },
              }),
            });
        message.success(t(order ? 'orders.add_edit_order.edit.success' : 'orders.add_edit_order.create.success'));
        navigate(-1);
      } catch (error) {
        message.error(t(order ? 'orders.add_edit_order.edit.error' : 'orders.add_edit_order.create.error'));
        sentryCaptureException(error, 'Add edit order, submit', userData);
        setSubmitting(false);
      }
    } catch (error) {
      message.error(t('common.form.complete_all_fields'));
      setSubmitting(false);
    }
  };

  return (
    <>
      <SharedPageHeader
        title={t(order ? 'orders.add_edit_order.edit.title' : 'orders.add_edit_order.create.title')}
        showBack
      />
      <div className='grid grid-cols-1 md:grid-cols-4 gap-4 grow pb-4'>
        {fetchingData || !patientData ? (
          <>
            <SkeletonElement width='100%' height='100%' />
            <SkeletonElement className='col-span-3' width='100%' height='100%' />
          </>
        ) : (
          <>
            <AddEditOrderDetails
              order={order}
              patient={patientData}
              orderDetailsForm={orderDetailsForm}
              submitting={submitting}
            />
            <div className='col-span-3'>
              <AddEditOrderHearingAids order={order} leftForm={leftEarProductForm} rightForm={rightEarProductForm} />
              <AddEditOrderAccessoriesServices current={accessoriesAndServices} set={setAccessoriesAndServices} />

              {(leftPrice || rightPrice || accessoriesAndServices.length > 0) && (
                <>
                  <AddEditOrderCosting order={order} grossPrice={grossPrice} form={costingForm} vatAmount={vatAmount} />
                  {['customerDeclaration', 'customerVerification', 'cancelationPolicy'].some(
                    (key) => !disabledFields.includes(key)
                  ) && <AddEditOrderCustomerDeclaration order={order} form={declarationForm} />}
                  <SharedCard>
                    <SharedForm
                      formInstance={confirmationForm}
                      fields={[
                        {
                          fieldKey: 'sendConfirmationEmail',
                          control: ControlType.Switch,
                          label: t('order.add_edit_order.communication.form.send_confirmation_email'),
                          required: true,
                        },
                        {
                          fieldKey: 'emailAddress',
                          control: ControlType.TextField,
                          type: InputType.Email,
                          label: t('order.add_edit_order.communication.form.email_address'),
                          required: true,
                          hidden: !sendConfirmationEmail,
                        },
                      ]}
                      name='orders.add_edit_order.communication'
                      existingValue={{
                        sendConfirmationEmail: order?.confirmationEmail.send ?? true,
                        emailAddress: order?.confirmationEmail.emailAddress ?? patientData.emailAddress,
                      }}
                      buttonsOverride={[]}
                    />
                  </SharedCard>
                  <div className='flex justify-end'>
                    <SharedButton
                      labelKey='common.submit'
                      onClick={() => submit()}
                      appearance='primary'
                      loading={submitting}
                    />
                  </div>
                </>
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default AddEditOrder;
