import { useDialog } from 'core/providers/dialog-provider';
import { useRef, useState } from 'react';
import { useUserState } from 'core/providers/user-provider';
import { ControlType } from 'core/enums/control-type';
import { useTranslation } from 'react-i18next';
import SharedForm from 'shared/form/shared-form';
import { ISharedField } from 'shared/fields/shared-fields.interface';
import SharedDialogBase from 'shared/dialog/dialog-base';
import { IDomainCalendarResource } from 'modules/organisation-settings/organisation-settings-slice';
import { AppointmentsApiService } from 'core/api';
import { App } from 'antd';
import { sentryCaptureException } from 'shared/helpers/sentry-helpers';

interface IPrintScheduleDialogFormOutput {
  user: string;
}

interface IPrintScheduleDialog {
  usersList: IDomainCalendarResource[];
  date: string | null;
}

const PrintScheduleDialog = ({ usersList, date }: IPrintScheduleDialog) => {
  const [loading, setLoading] = useState(false);
  const dialog = useDialog();
  const { userData } = useUserState();
  const { t } = useTranslation();
  const { message } = App.useApp();
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const [blobUrl, setBlobUrl] = useState<string | null>(null);

  const fields: ISharedField[] = [
    {
      fieldKey: 'user',
      control: ControlType.Select,
      options: usersList.map((user) => ({ label: user.fullName, value: user.uid })),
      label: t('appointments.appointments_calendar.print_schedule_dialog.form.user'),
      required: true,
    },
  ];

  const submit = async (data: IPrintScheduleDialogFormOutput) => {
    setLoading(true);

    try {
      if (!date) {
        throw new Error('Date is required');
      }
      const dataUrl = await AppointmentsApiService.printAppointmentSchedule({
        userUid: data.user,
        date,
      });
      const blob = await fetch(dataUrl).then((res) => res.blob());
      const blobURL = URL.createObjectURL(blob);
      setBlobUrl(blobURL);
      const newTab = window.open(blobURL, '_blank');
      if (newTab) {
        newTab.onload = () => URL.revokeObjectURL(blobURL);
      }
      dialog?.closeDialog();
    } catch (error) {
      message.error(t('appointments.appointments_calendar.print_schedule_dialog.error'));
      sentryCaptureException(error, 'Creating appointment schedule PDF', userData);
      setLoading(false);
    }
  };

  const customContent = () => {
    return (
      <div className='p-4 overflow-y-auto'>
        <p className='body-sm mb-4'>{t('appointments.appointments_calendar.print_schedule_dialog.instructions')}</p>
        <SharedForm
          fields={fields}
          onFinish={submit}
          submitting={loading}
          name='print-schedule-dialog'
          className='p-0'
          cancelButton={{ labelKey: 'common.cancel', appearance: 'text', onClick: () => dialog?.closeDialog() }}
          existingValue={
            usersList.length === 1
              ? {
                  user: usersList[0].uid,
                }
              : undefined
          }
        />
        {blobUrl && <iframe ref={iframeRef} src={blobUrl} className='hidden' title='Print Schedule' />}
      </div>
    );
  };

  return (
    <SharedDialogBase
      title={t('appointments.appointments_calendar.print_schedule_dialog.title')}
      customContentTemplate={customContent()}
      showButtons={false}
    />
  );
};

export default PrintScheduleDialog;
