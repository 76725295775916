import { CollectionID } from 'core/constants/collection-id';
import { Permission } from 'core/constants/permission';
import { useDialog } from 'core/providers/dialog-provider';
import { useUserState } from 'core/providers/user-provider';
import { where } from 'firebase/firestore';
import { useTranslation } from 'react-i18next';
import SharedButton from 'shared/button/button';
import SharedCard from 'shared/card/card';
import SharedPageHeader from 'shared/page-header/page-header';
import SharedElementPermissionGuard from 'shared/permissions/element-permission-guard';
import SharedPaginatedTable from 'shared/table/paginated-table';
import AddEditHolidayUnavailabilityDialog from './add-edit-holiday-unavailability-dialog';
import { ISharedTableColumn } from 'shared/table/table.interface';
import { IHolidayAndUnavailabilityDao } from 'core/api/types/holiday-and-unavailability.interface';
import { RepeatFrequencyData } from 'core/constants/repeat-frequency';
import dayjs from 'dayjs';
import { UnavailabilityTypeData } from 'core/constants/unavailability-type';
import ConfirmActionDialog from 'shared/dialog/confirm-action-dialog';
import { HolidayAndUnavailabilityApiService } from 'core/api';

const tableColumns: ISharedTableColumn[] = [
  {
    labelKey: 'holidays_and_unavailability.list.table.assignee',
    key: 'assignee',
    contentTemplateId: 'assignee',
  },
  {
    labelKey: 'holidays_and_unavailability.list.table.reason',
    key: 'reason',
    contentTemplateId: 'reason',
  },
  {
    labelKey: 'holidays_and_unavailability.list.table.repeats',
    key: 'repeats',
    contentTemplateId: 'repeats',
    width: 120,
  },
  {
    labelKey: 'holidays_and_unavailability.list.table.dates',
    key: 'dates',
    contentTemplateId: 'dateRange',
  },
  {
    labelKey: 'holidays_and_unavailability.list.table.time',
    key: 'time',
    contentTemplateId: 'time',
  },
  {
    labelKey: 'holidays_and_unavailability.list.table.updated_at',
    key: 'updated',
    contentTemplateId: 'lastUpdated',
  },
  { key: 'action', contentTemplateId: 'actions' },
];

const HolidaysAndAvailabilityList = () => {
  const { t } = useTranslation();
  const { userData } = useUserState();
  const tableKey = 'holidays_and_unavailability.list.table';
  const dialog = useDialog();

  const assigneeTemplate = (data: IHolidayAndUnavailabilityDao) => {
    return <p className='body-sm whitespace-pre-wrap'>{data.assignee.fullName}</p>;
  };

  const reasonTemplate = (data: IHolidayAndUnavailabilityDao) => {
    const typeData = UnavailabilityTypeData[data.type];
    return (
      <p className='body-sm whitespace-pre-wrap'>{`${t(typeData.translationLabelKey)} ${
        data.description ? `(${data.description})` : ''
      }`}</p>
    );
  };

  const repeatsTemplate = (data: IHolidayAndUnavailabilityDao) => {
    if (!data.repeat.isRepeating || !data.repeat.frequency || !data.repeat.until) {
      return <p className='body-sm'>{t('common.no')}</p>;
    }
    const frequencyData = RepeatFrequencyData[data.repeat.frequency];
    return (
      <p className='body-sm whitespace-pre-wrap'>{`${t('common.yes')}, ${t(frequencyData.translationLabelKey)}`}</p>
    );
  };

  const datesTemplate = (data: IHolidayAndUnavailabilityDao) => {
    return (
      <p className='body-sm whitespace-pre-wrap'>{`${dayjs(data.startDateTime.toDate()).format('DD/MM/YYYY')} - ${dayjs(
        data.endDateTime.toDate()
      ).format('DD/MM/YYYY')}`}</p>
    );
  };

  const timeTemplate = (data: IHolidayAndUnavailabilityDao) => {
    return (
      <p className='body-sm whitespace-pre-wrap'>{`${dayjs(data.startDateTime.toDate()).format('HH:mm')} - ${dayjs(
        data.endDateTime.toDate()
      ).format('HH:mm')}`}</p>
    );
  };

  const actionsTemplate = (data: IHolidayAndUnavailabilityDao) => {
    return (
      <div className='w-full flex justify-end space-x-4'>
        <SharedElementPermissionGuard
          requiredPermissions={[[Permission.HOLIDAYS_UPDATE], [Permission.ORGANISATION_OWNER]]}
        >
          <SharedButton
            onClick={() =>
              dialog?.openDialog(<AddEditHolidayUnavailabilityDialog tableKey={tableKey} existingItem={data} />)
            }
            type='button'
            appearance='link'
            labelKey='common.edit'
            primaryOverride
          />
        </SharedElementPermissionGuard>
        <SharedElementPermissionGuard
          requiredPermissions={[[Permission.HOLIDAYS_DELETE], [Permission.ORGANISATION_OWNER]]}
        >
          <SharedButton
            onClick={() =>
              dialog?.openDialog(
                <ConfirmActionDialog
                  action={() => HolidayAndUnavailabilityApiService.permDelete(data.uid)}
                  componentPortalKeys={[tableKey]}
                  actionButtonProps={{
                    labelKey: 'common.delete',
                    danger: true,
                  }}
                  title={t('holidays_and_unavailability.list.delete.title')}
                  textContent={t('holidays_and_unavailability.list.delete.content')}
                  successMessage={t('holidays_and_unavailability.list.delete.success')}
                  errorMessage={t('holidays_and_unavailability.list.delete.error')}
                />
              )
            }
            type='button'
            appearance='link'
            labelKey='common.delete'
            danger
          />
        </SharedElementPermissionGuard>
      </div>
    );
  };

  const headerActions = [
    {
      element: (
        <SharedElementPermissionGuard
          requiredPermissions={[[Permission.HOLIDAYS_CREATE], [Permission.ORGANISATION_OWNER]]}
        >
          <SharedButton
            labelKey='holidays_and_unavailability.list.header.add'
            onClick={() => dialog?.openDialog(<AddEditHolidayUnavailabilityDialog tableKey={tableKey} />)}
            appearance='primary'
          />
        </SharedElementPermissionGuard>
      ),
      key: 'addUser',
    },
  ];

  return (
    <>
      <SharedPageHeader title={t('navigation.holidays_and_unavailability')} actions={headerActions} />
      {userData && (
        <SharedCard>
          <SharedPaginatedTable
            collectionId={CollectionID.HOLIDAYS_AND_UNAVAILABILITY}
            queryConstraints={[where('organisationUid', '==', userData?.organisationUid)]}
            queryOrder={['updated.at', 'desc']}
            tableConfig={{
              columns: tableColumns,
              contentTemplates: [
                {
                  template: assigneeTemplate,
                  id: 'assignee',
                },
                {
                  template: reasonTemplate,
                  id: 'reason',
                },
                {
                  template: repeatsTemplate,
                  id: 'repeats',
                },
                {
                  template: datesTemplate,
                  id: 'dateRange',
                },
                {
                  template: timeTemplate,
                  id: 'time',
                },
                {
                  template: actionsTemplate,
                  id: 'actions',
                },
              ],
            }}
            errorMessageKey='holidays_and_unavailability.list.table.error'
            tableKey={tableKey}
          />
        </SharedCard>
      )}
    </>
  );
};

export default HolidaysAndAvailabilityList;
