import { ControlType } from 'core/enums/control-type';
import { useTranslation } from 'react-i18next';
import SharedDialogBase from 'shared/dialog/dialog-base';
import { ISharedField } from 'shared/fields/shared-fields.interface';
import SharedForm from 'shared/form/shared-form';
import { IOrderAccessoryOrService } from './add-edit-order';
import { useDialog } from 'core/providers/dialog-provider';
import { IAccessoryDao, IServiceDao } from 'core/api/types';
import {
  IDomainOrganisationDataType,
  OrganisationSettingsSlice,
} from 'modules/organisation-settings/organisation-settings-slice';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Form } from 'antd';

interface IAddEditOrderAddAccessoryServiceDialog {
  action: (data: IOrderAccessoryOrService) => void;
  current?: IOrderAccessoryOrService;
}

interface AddEditOrderAddAccessoryServiceDialogFormOutput {
  type: string;
  manufacturer?: string;
  accessoryName?: string;
  serviceName?: string;
  price: number;
}

const AddEditOrderAddAccessoryServiceDialog = ({ action, current }: IAddEditOrderAddAccessoryServiceDialog) => {
  const { t } = useTranslation();
  const dialog = useDialog();
  const accessoryState = useSelector(OrganisationSettingsSlice.selectAccessories);
  const servicesState = useSelector(OrganisationSettingsSlice.selectServices);
  const [form] = Form.useForm();
  const [matchedAccessory, setMatchedAccessory] = useState<IDomainOrganisationDataType<IAccessoryDao>>();
  const manufacturersList = [
    ...new Set(accessoryState?.data.filter((accessory) => !accessory.deleted).map((acc) => acc.manufacturer)),
  ];
  const [matchedService, setMatchedService] = useState<IDomainOrganisationDataType<IServiceDao>>();
  const [{ type, manufacturer, price }, setWatchedValues] = useState<
    Partial<AddEditOrderAddAccessoryServiceDialogFormOutput>
  >({});
  const accessories = useMemo(
    () => [
      ...new Set(
        accessoryState?.data
          .filter((acc) => !acc.deleted && acc.manufacturer === manufacturer)
          .map((acc) => acc.accessoryName)
      ),
    ],
    [accessoryState?.data, manufacturer]
  );

  useEffect(() => {
    if (current) {
      setWatchedValues({ type: current.type, manufacturer: current?.manufacturer, price: current.price });
      if (current.type === 'accessory') {
        const matchedAccessory = accessoryState?.data.find(
          (acc) => acc.manufacturer === current.manufacturer && acc.accessoryName === current.name
        );
        setMatchedAccessory(matchedAccessory);
      } else {
        const matchedService = servicesState?.data.find((service) => service.serviceName === current.name);
        setMatchedService(matchedService);
      }
    }
  }, [accessoryState?.data, current, servicesState?.data]);

  useEffect(() => {
    if (price === undefined) {
      if (matchedAccessory) {
        form.setFieldsValue({
          price: matchedAccessory.rrp,
        });
      }
      if (matchedService) {
        form.setFieldsValue({
          price: matchedService.rrp,
        });
      }
    }
  }, [form, matchedAccessory, matchedService, price]);

  const fields: ISharedField[] = [
    {
      fieldKey: 'type',
      control: ControlType.Select,
      options: [
        {
          value: 'accessory',
          label: t('orders.add_edit_order.add_accessory_service.form.accessory'),
        },
        {
          value: 'service',
          label: t('orders.add_edit_order.add_accessory_service.form.service'),
        },
      ],
      label: t('orders.add_edit_order.add_accessory_service.form.type'),
      required: true,
    },
    {
      fieldKey: 'manufacturer',
      control: ControlType.Select,
      options: manufacturersList.map((manufacturer) => ({ label: manufacturer, value: manufacturer })),
      label: t('orders.add_edit_order.add_accessory_service.form.manufacturer'),
      required: true,
      hidden: type !== 'accessory',
    },
    {
      fieldKey: 'accessoryName',
      control: ControlType.Select,
      options: accessories.map((acc) => ({ label: acc, value: acc })),
      label: t('orders.add_edit_order.add_accessory_service.form.accessory'),
      required: true,
      hidden: !manufacturer,
    },
    {
      fieldKey: 'serviceName',
      control: ControlType.Select,
      options: servicesState?.data.map((service) => ({ label: service.serviceName, value: service.serviceName })) ?? [],
      label: t('orders.add_edit_order.add_accessory_service.form.service'),
      required: true,
      hidden: type !== 'service',
    },
    {
      fieldKey: 'price',
      control: ControlType.NumberField,
      label: t('common.price'),
      required: true,
      hidden: !type || (type === 'accessory' && !matchedAccessory) || (type === 'service' && !matchedService),
      fullWidth: true,
      min: 0,
    },
  ];

  const customContent = () => {
    return (
      <SharedForm<AddEditOrderAddAccessoryServiceDialogFormOutput>
        formInstance={form}
        onChange={(change) => {
          setWatchedValues((prev) => ({ ...prev, ...change }));
          const [key, value] = Object.entries(change)[0];
          switch (key) {
            case 'type': {
              form.setFieldsValue({
                manufacturer: undefined,
                accessoryName: undefined,
                serviceName: undefined,
              });
              setWatchedValues({ type: value as string });
              setMatchedAccessory(undefined);
              setMatchedService(undefined);
              break;
            }
            case 'manufacturer':
              form.setFieldsValue({
                accessoryName: undefined,
              });
              setMatchedAccessory(undefined);
              break;
            case 'serviceName':
              {
                const matchedService = servicesState?.data.find((service) => service.serviceName === value);
                setMatchedService(matchedService);
              }
              break;
            case 'accessoryName': {
              const matchedAccessory = accessoryState?.data.find(
                (acc) => acc.manufacturer === manufacturer && acc.accessoryName === value
              );
              setMatchedAccessory(matchedAccessory);
            }
          }
        }}
        onFinish={(data) => {
          action({
            itemKey: data.type === 'accessory' ? matchedAccessory?.uid! : matchedService?.uid!,
            type: data.type,
            name: data.type === 'accessory' ? matchedAccessory?.accessoryName! : matchedService?.serviceName!,
            price: data.price,
            ...(manufacturer && { manufacturer: manufacturer }),
          });
          dialog?.closeDialog();
        }}
        fields={fields}
        cancelButton={{ labelKey: 'common.cancel', appearance: 'text', onClick: () => dialog?.closeDialog() }}
        name='add_accessory_service-form'
        existingValue={{
          type: current?.type,
          manufacturer: current?.manufacturer,
          accessoryName: current?.type === 'accessory' ? current?.name : undefined,
          serviceName: current?.type === 'service' ? current?.name : undefined,
          price: current?.price,
        }}
      />
    );
  };

  return (
    <SharedDialogBase
      title={t(current ? 'order.add_edit_order.edit_accessory_service' : 'order.add_edit_order.add_accessory_service')}
      customContentTemplate={customContent()}
      showButtons={false}
    />
  );
};

export default AddEditOrderAddAccessoryServiceDialog;
