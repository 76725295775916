import { Dropdown, MenuProps } from 'antd';
import { ISharedCalendarColumnResource } from './calendar-daily-column';
import { IAppointmentDao } from 'core/api/types/appointment.interface';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { useDialog } from 'core/providers/dialog-provider';
import TimelinePreviewDialog from 'shared/dialog/timeline-preview-dialog';
import { Dayjs } from 'dayjs';
import { useSelector } from 'react-redux';
import {
  IDomainCalendarResource,
  OrganisationSettingsSlice,
} from 'modules/organisation-settings/organisation-settings-slice';
import { MapPin } from 'react-feather';
import { CalendarTimeframe } from 'core/constants/calendar-timeframe';
import { Permission } from 'core/constants/permission';
import { usePermissionsState } from 'core/providers/permissions-provider';
import SetWorkingLocationDialog from 'shared/dialog/set-working-location-dialog';
interface ICalendarDailyColumnHeader {
  resource: ISharedCalendarColumnResource;
  showAppointmentMenu: boolean;
  resourceAppointments: IAppointmentDao[];
  resourceHeaderHeight: number;
  currentDate: Dayjs;
  timeframe: CalendarTimeframe;
}

const CalendarDailyColumnHeader = ({
  resource,
  showAppointmentMenu,
  resourceAppointments,
  resourceHeaderHeight,
  currentDate,
  timeframe,
}: ICalendarDailyColumnHeader) => {
  const { t } = useTranslation();
  const dialog = useDialog();
  const clinicsState = useSelector(OrganisationSettingsSlice.selectClinics);
  const isDayView = timeframe === CalendarTimeframe.DAY;
  const { userPermissions } = usePermissionsState();

  const getWorkingLocation = (resource: ISharedCalendarColumnResource) => {
    if (resource.type === 'clinic') {
      return <></>;
    }
    const day = currentDate.day();
    const override = resource.details.workingLocationOverrides?.[currentDate.format('YYYY-MM-DD')];
    const todaysLocation = override ? override : resource.details.workingLocations?.[day];
    let label: string | undefined;

    switch (todaysLocation) {
      case 'domiciliary':
        label = t('working_location.domiciliary');
        break;
      case 'nonWorking':
        label = t('working_location.non_working');
        break;
      default: {
        const matchingClinic = clinicsState?.data.find((clinic) => clinic.uid === todaysLocation);
        if (matchingClinic) {
          label = matchingClinic.name;
        }
      }
    }

    if (!label) {
      return <></>;
    }

    return (
      <div className='body-xs text-gray-400 flex space-x-1 items-center justify-center'>
        <MapPin size={12} />
        <p>{label}</p>
      </div>
    );
  };

  const hasPermission = (permissions: Permission[]) =>
    [[Permission.ORGANISATION_OWNER], permissions].some((permissions) =>
      permissions.every((permission) => userPermissions?.includes(permission))
    );

  const items = [
    {
      key: 'viewRoute',
      label: t('calendar.calendar_column.resource_header.view_todays_route'),
      hide: !showAppointmentMenu || resource.type === 'clinic',
    },
    {
      key: 'setWorkingLocation',
      label: t('calendar.calendar_column.resource_header.set_working_location'),
      hide: !hasPermission([Permission.USERS_UPDATE]) || !isDayView,
    },
  ];

  const onClick: MenuProps['onClick'] = ({ key }) => {
    switch (key) {
      case 'viewRoute':
        dialog?.openDialog(
          <TimelinePreviewDialog
            appointments={resourceAppointments}
            resource={resource.details as IDomainCalendarResource}
          />
        );
        break;
      case 'setWorkingLocation':
        dialog?.openDialog(
          <SetWorkingLocationDialog user={resource.details as IDomainCalendarResource} date={currentDate} />
        );
        break;
      default:
        break;
    }
  };

  return (
    <Dropdown
      menu={{
        items: items.filter((item) => !item.hide).map(({ hide, ...rest }) => rest),
        onClick,
      }}
      placement='bottomRight'
      trigger={['click']}
    >
      <button
        className={clsx(
          'bg-gray-50 text-center shrink-0 grow-0 sticky top-0 z-30 transition ease-in-out border-b',
          showAppointmentMenu && 'cursor-pointer hover:bg-gray-100'
        )}
        style={{ flexBasis: `${resourceHeaderHeight}px` }}
      >
        <p className='font-semibold'>
          {isDayView ? resource.label : resource.label.split(' ').map((str) => str.charAt(0).toUpperCase())}
        </p>
        {isDayView && getWorkingLocation(resource)}
      </button>
    </Dropdown>
  );
};

export default CalendarDailyColumnHeader;
