import { DatePicker } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { ReactNode } from 'react';
import { ChevronLeft, ChevronRight } from 'react-feather';
import SharedButton from 'shared/button/button';

interface ISharedCalendarHeader {
  currentDate: Dayjs;
  changeDate: (newDate: string) => void;
  minDate?: Dayjs;
  headerHeight: number;
  extra?: ReactNode;
  weekly?: boolean;
  enabledDays: number[];
}

const SharedCalendarHeader = ({
  currentDate,
  changeDate,
  minDate,
  headerHeight,
  extra,
  weekly,
  enabledDays,
}: ISharedCalendarHeader) => {
  const handleArrowClick = (direction: 'left' | 'right') => {
    const movement = direction === 'left' ? -1 : 1;
    let date = dayjs(currentDate);

    if (weekly) {
      date = date.add(movement, 'week');
    } else {
      date = calculateDayMovement(date, movement);
    }

    changeDate(date.format('YYYY-MM-DD'));
  };

  const calculateDayMovement = (current: Dayjs, movement: -1 | 1) => {
    const next = current.add(movement, 'day');
    if (enabledDays.includes(next.day())) {
      return next;
    } else {
      return calculateDayMovement(next, movement);
    }
  };

  const weekFormat = 'DD/MM/YYYY';
  const customWeekStartEndFormat = (value: Dayjs) =>
    `${dayjs(value).startOf('week').format(weekFormat)} ~ ${dayjs(value).endOf('week').format(weekFormat)}`;

  return (
    <div
      className='grow-0 shrink-0 flex flex-col md:flex-row px-2 items-stretch md:items-center border-b justify-between space-y-2 md:space-y-0 py-2 md:py-0 md:space-x-1'
      style={{ flexBasis: `${headerHeight}px` }}
    >
      <div className='grow flex items-center space-x-1'>
        <SharedButton
          disabled={minDate !== undefined && currentDate <= minDate}
          icon={<ChevronLeft size={20} />}
          onClick={() => handleArrowClick('left')}
        />
        <SharedButton icon={<ChevronRight size={20} />} onClick={() => handleArrowClick('right')} />
        <DatePicker
          className='md:min-w-[320px] grow md:grow-0'
          allowClear={false}
          format={weekly ? customWeekStartEndFormat : 'dddd, D MMMM'}
          picker={weekly ? 'week' : 'date'}
          value={currentDate}
          onChange={(date) => changeDate(date.format('YYYY-MM-DD'))}
          disabledDate={(date) => !enabledDays.includes(date.day())}
        />
      </div>
      <div className='flex space-x-2 self-end md:self-center'>{extra}</div>
    </div>
  );
};

export default SharedCalendarHeader;
