import { App, Tag } from 'antd';
import clsx from 'clsx';
import { CommunicationApiService } from 'core/api';
import { IPatientDao } from 'core/api/types';
import { ICommunicationDao } from 'core/api/types/communication.interface';
import { CommunicationMethodData } from 'core/constants/communication-method';
import { CommunicationStatusData } from 'core/constants/communication-status';
import { CommunicationTypeData } from 'core/constants/communication-type';
import { useUserState } from 'core/providers/user-provider';
import dayjs from 'dayjs';
import { Unsubscribe } from 'firebase/auth';
import { orderBy, where } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SharedCard from 'shared/card/card';
import { sentryCaptureException } from 'shared/helpers/sentry-helpers';
import SharedTable from 'shared/table/table';
import { ISharedTableColumn, ISharedTableCustomTemplate } from 'shared/table/table.interface';

const tableColumns: ISharedTableColumn[] = [
  {
    labelKey: 'patients.patient.communications.table.header.status',
    key: 'status',
    contentTemplateId: 'status',
  },
  {
    labelKey: 'patients.patient.communications.table.header.type',
    key: 'type',
    contentTemplateId: 'type',
  },
  {
    labelKey: 'patients.patient.communications.table.header.method',
    key: 'method',
    contentTemplateId: 'method',
  },
  {
    labelKey: 'patients.patient.communications.table.header.destination',
    key: 'destination',
    width: 300,
  },
  {
    labelKey: 'patients.patient.communications.table.header.date_sent',
    key: 'dateSent',
    contentTemplateId: 'dateSent',
  },
  {
    labelKey: 'patients.patient.communications.table.header.created',
    key: 'created',
    contentTemplateId: 'created',
    width: 300,
  },
];

const PatientCommunicationsList = (patient: IPatientDao) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const [communications, setCommunications] = useState<ICommunicationDao[]>([]);
  const { userData } = useUserState();
  const { message } = App.useApp();

  useEffect(() => {
    let unsubscribe: Unsubscribe;
    setLoading(true);
    unsubscribe = CommunicationApiService.onCollectionSnapshot(
      (snap) => {
        setCommunications(snap.docs.map((doc) => doc.data()));
        setLoading(false);
      },
      (error) => {
        message.error(t('patients.patient.communications.error'));
        sentryCaptureException(error, 'Patient file fetching communications', userData);
      },
      [
        where('organisationUid', '==', userData?.organisationUid),
        where('patientUid', '==', patient.uid),
        orderBy('dateSent', 'desc'),
      ]
    );
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [message, patient.uid, t, userData]);

  const dateTemplate = (communication: ICommunicationDao) => {
    return (
      <p className='body-sm'>
        {communication.dateSent
          ? dayjs(communication.dateSent?.toDate()).format('DD/MM/YYYY')
          : dayjs(communication.created.at?.toDate()).format('DD/MM/YYYY')}
      </p>
    );
  };

  const createdTemplate = (communication: ICommunicationDao) => {
    return (
      <p className='body-sm'>
        {t('common.at_by', {
          at: dayjs(communication.created.at.toDate()).format('DD/MM/YYYY, HH:mm'),
          by: communication.created.by.fullName,
        })}
      </p>
    );
  };

  const statusTemplate = (communication: ICommunicationDao) => {
    const statusData = CommunicationStatusData[communication.status];
    return <Tag color={statusData?.color ?? 'default'}>{t(statusData?.translationLabelKey ?? 'common.unknown')}</Tag>;
  };

  const methodTemplate = (communication: ICommunicationDao) => {
    const methodData = CommunicationMethodData[communication.method];
    return (
      <p className={clsx('body-sm', !methodData && 'text-gray-300')}>
        {t(methodData?.translationLabelKey ?? 'common.unknown')}
      </p>
    );
  };

  const typeTemplate = (communication: ICommunicationDao) => {
    const typeData = CommunicationTypeData[communication.type];
    return (
      <p className={clsx('body-sm', !typeData && 'text-gray-300')}>
        {t(typeData?.translationLabelKey ?? 'common.unknown')}
      </p>
    );
  };

  const contentTemplates: ISharedTableCustomTemplate[] = [
    {
      template: dateTemplate,
      id: 'dateSent',
    },
    {
      template: statusTemplate,
      id: 'status',
    },
    {
      template: methodTemplate,
      id: 'method',
    },
    {
      template: typeTemplate,
      id: 'type',
    },
    {
      template: createdTemplate,
      id: 'created',
    },
  ];

  return (
    <SharedCard title={t('patients.patient.communications.title')}>
      <SharedTable
        loading={loading}
        rows={communications.map((communication) => ({
          key: communication.uid,
          data: communication,
        }))}
        columns={tableColumns}
        contentTemplates={contentTemplates}
        headerBackgroundColor='#f8fafc'
      />
    </SharedCard>
  );
};

export default PatientCommunicationsList;
