import { ControlType } from 'core/enums/control-type';
import { useDialog } from 'core/providers/dialog-provider';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SharedDialogBase from 'shared/dialog/dialog-base';
import { useUserState } from 'core/providers/user-provider';
import SharedForm from 'shared/form/shared-form';
import { ISharedField } from 'shared/fields/shared-fields.interface';
import { App } from 'antd';
import { IAppointmentDao } from 'core/api/types/appointment.interface';
import { useSelector } from 'react-redux';
import { OrganisationSettingsSlice } from 'modules/organisation-settings/organisation-settings-slice';
import { IAppointmentTypeOutcomeDao } from 'core/api/types/appointment-types.interface';
import { AppointmentsApiService } from 'core/api';
import { getActionTimestampFromUser } from 'shared/helpers/user-action.helpers';
import { deleteField } from 'firebase/firestore';

interface ISetAppointmentOutcomeOutput {
  outcome?: string;
}

interface ISetAppointmentOutcomeDialog {
  appointment: IAppointmentDao;
}
const SetAppointmentOutcomeDialog = ({ appointment }: ISetAppointmentOutcomeDialog) => {
  const { userData } = useUserState();
  const { message } = App.useApp();
  const { t } = useTranslation();
  const [submitting, setSubmitting] = useState(false);
  const dialog = useDialog();

  const appointmentTypes = useSelector(OrganisationSettingsSlice.selectAppointmentTypes);

  const [outcomes, setOutcomes] = useState<IAppointmentTypeOutcomeDao[]>([]);

  const userTimestamp = getActionTimestampFromUser(userData);

  useEffect(() => {
    const appointmentTypeOutcomes =
      appointmentTypes?.data.find((type) => type.uid === appointment.type)?.outcomes ?? [];
    setOutcomes(appointmentTypeOutcomes);
  }, [appointmentTypes, appointment.type]);

  const SetAppointmentOoutcomeFields: ISharedField[] = [
    {
      fieldKey: 'outcome',
      control: ControlType.Select,
      label: t('dialog.set_appointment_outcome.outcome'),
      options: outcomes.map((outcome) => ({ label: outcome.bannerLabel, value: outcome.key })),
      required: false,
      allowClear: true,
    },
  ];

  const submit = async ({ outcome }: ISetAppointmentOutcomeOutput) => {
    setSubmitting(true);
    try {
      if (!userData?.organisationUid) {
        throw new Error(t('auth.user.error'));
      }
      await AppointmentsApiService.update(appointment.uid, {
        outcome: outcome ? outcome : deleteField(),
        updated: userTimestamp,
      });
      message.success(t('dialog.set_appointment_outcome.success.description'));
      dialog?.closeDialog();
    } catch (err) {
      message.error(t('dialog.set_appointment_outcome.error.description'));
      setSubmitting(false);
    }
  };

  const customContent = () => {
    return (
      <SharedForm<ISetAppointmentOutcomeOutput>
        onFinish={submit}
        fields={SetAppointmentOoutcomeFields}
        submitting={submitting}
        cancelButton={{ labelKey: 'common.cancel', appearance: 'text', onClick: () => dialog?.closeDialog() }}
        name='set-appointment-outcome'
        existingValue={appointment}
      />
    );
  };

  return (
    <SharedDialogBase
      title={t('dialog.set_appointment_outcome.title')}
      customContentTemplate={customContent()}
      showButtons={false}
    />
  );
};

export default SetAppointmentOutcomeDialog;
