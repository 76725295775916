import { sentryCaptureException } from 'shared/helpers/sentry-helpers';
import {
  IGetAddressAddress,
  IGetAddressSuggestions,
  IGoogleAutocompleteSuggestions,
  IGoogleMapsAddress,
} from './types/address.interface';
import { IAddressDao } from 'shared/interfaces/address.interface';
import { createAddressString } from 'shared/helpers/address-string-builder';
import { IManualAddressFormOutput } from 'shared/fields/address/manual-address-form';

const API_KEY = process.env.REACT_APP_GET_ADDRESS_KEY;

const autocompleteUK = async (term: string): Promise<IGetAddressSuggestions> => {
  try {
    const response = await fetch(`https://api.getAddress.io/autocomplete/${term}?api-key=${API_KEY}&all=true&top=20`);
    const json = await response.json();
    return json as IGetAddressSuggestions;
  } catch (error) {
    sentryCaptureException(error, 'GetAddress autocomplete fetch');
    throw error;
  }
};

const getFullAddressUK = async (id: string): Promise<IGetAddressAddress> => {
  try {
    const response = await fetch(`https://api.getAddress.io//get/${id}?api-key=${API_KEY}`);
    const json = await response.json();
    return json as IGetAddressAddress;
  } catch (error) {
    sentryCaptureException(error, 'GetAddress full address fetch');
    throw error;
  }
};

const autocompleteGoogle = async (term: string, countryCode: string): Promise<IGoogleAutocompleteSuggestions> => {
  try {
    const token = new google.maps.places.AutocompleteSessionToken();
    const { fetchAutocompleteSuggestions } = google.maps.places.AutocompleteSuggestion;
    const response = await fetchAutocompleteSuggestions({
      input: term,
      sessionToken: token,
      includedRegionCodes: [countryCode],
    });
    return {
      suggestions: response.suggestions.map(({ placePrediction }) => ({
        address: placePrediction?.text.toString(),
        placePredication: placePrediction,
        id: placePrediction?.placeId,
      })),
    };
  } catch (error) {
    sentryCaptureException(error, 'GetAddress autocomplete fetch');
    throw error;
  }
};

const getFullAddressGoogle = async (
  placePrediction: google.maps.places.PlacePrediction | null
): Promise<IGoogleMapsAddress> => {
  if (!placePrediction) {
    throw new Error('No place prediction');
  }
  try {
    const place = placePrediction.toPlace();
    await place.fetchFields({
      fields: ['formattedAddress', 'addressComponents', 'location'],
    });
    const postcode =
      place.addressComponents?.find((component) => component.types.includes('postal_code'))?.longText ?? '';
    if (!postcode || !place.location || !place.formattedAddress) {
      throw new Error('No postcode, formatted address or location');
    }
    return {
      postcode,
      latitude: place.location.lat(),
      longitude: place.location.lng(),
      formattedAddress: place.formattedAddress,
    };
  } catch (error) {
    sentryCaptureException(error, 'Google full address fetch');
    throw error;
  }
};

const getFullAddressFromAddressFormOutputGoogle = async (
  address: Partial<IManualAddressFormOutput>
): Promise<IAddressDao | undefined> => {
  try {
    if (!address.addressLine1 || !address.postcode) {
      throw new Error('No address line 1 or postcode');
    }
    const formattedAddress = createAddressString(Object.values(address));
    const geocoder = new google.maps.Geocoder();
    const response = await geocoder.geocode({ address: formattedAddress });

    if (!response || response.results.length === 0) {
      return undefined;
    }
    const place = response.results[0];

    return {
      postcode: address.postcode,
      lat: place.geometry.location.lat(),
      lng: place.geometry.location.lng(),
      formattedAddress,
    };
  } catch (error) {
    sentryCaptureException(error, 'Google full address fetch from string');
    throw error;
  }
};

const AddressApiService = {
  autocompleteUK,
  getFullAddressUK,
  autocompleteGoogle,
  getFullAddressGoogle,
  getFullAddressFromAddressFormOutputGoogle,
};

export default AddressApiService;
