import { App, Dropdown, MenuProps } from 'antd';
import { AppointmentsApiService, HolidayAndUnavailabilityApiService } from 'core/api';
import { IUserDao } from 'core/api/types';
import { IAppointmentDao } from 'core/api/types/appointment.interface';
import { IHolidayAndUnavailabilityDao } from 'core/api/types/holiday-and-unavailability.interface';
import { Permission } from 'core/constants/permission';
import { useDialog } from 'core/providers/dialog-provider';
import { usePermissionsState } from 'core/providers/permissions-provider';
import { useUserState } from 'core/providers/user-provider';
import dayjs, { Dayjs } from 'dayjs';
import { and, FirestoreError, or, orderBy, Unsubscribe, where } from 'firebase/firestore';
import AddEditHolidayUnavailabilityDialog from 'modules/organisation-settings/holidays-and-unavailability/add-edit-holiday-unavailability-dialog';
import { OrganisationSettingsSlice } from 'modules/organisation-settings/organisation-settings-slice';
import AddEditPatientDialog from 'modules/patients/add-edit-patient-dialog';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Plus, Printer } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import SharedButton from 'shared/button/button';
import SharedCalendar from 'shared/calendar/calendar';
import PatientSearchDialog from 'shared/dialog/patient-search-dialog';
import DrawerFilter from 'shared/filter/drawer-filter';
import { IFilter } from 'shared/filter/filter';
import { getAppointment60MinuteTimeSlots } from 'shared/helpers/appointment-helpers';
import { sentryCaptureException } from 'shared/helpers/sentry-helpers';
import SharedElementPermissionGuard from 'shared/permissions/element-permission-guard';
import BulkAddAppointmentsDialog from './bulk-add-appointments-dialog';
import PrintScheduleDialog from './print-schedule-dialog';

const AppointmentsCalendar = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const calendarResourcesState = useSelector(OrganisationSettingsSlice.selectCalendarResources);
  const calendarResources = calendarResourcesState?.data || [];
  const clinicsState = useSelector(OrganisationSettingsSlice.selectClinics);
  const date = searchParams.get('date');
  const navigate = useNavigate();
  const [appointments, setAppointments] = useState<IAppointmentDao[]>([]);
  const [assigneeUnavailability, setAssigneeUnavailability] = useState<Record<string, IHolidayAndUnavailabilityDao[]>>(
    {}
  );
  const { message } = App.useApp();
  const { t } = useTranslation();
  const { userData, organisationData } = useUserState();
  const dialog = useDialog();
  const [activeFilters, setActiveFilters] = useState<{ [key: string]: string[] }>({});
  const [clinicList, setClinicList] = useState<{ value: string; label: string }[]>([]);
  const calendarWrapperRef = useRef<HTMLDivElement>(null);
  const [startDate, setStartDate] = useState<Date>();
  const { userPermissions } = usePermissionsState();
  const [loadingAppointments, setLoadingAppointments] = useState(true);
  const [loadingUnavailability, setLoadingUnavailability] = useState<Record<string, boolean>>({});

  const loading =
    loadingAppointments ||
    Object.values(loadingUnavailability).some((val) => val) ||
    !date ||
    calendarResourcesState?.status !== 'success';

  const filters: IFilter[] = [
    {
      key: 'clinic',
      label: t('calendar.filters.clinic'),
      options: clinicList,
      mode: 'multiple',
      maxCount: 1,
    },
    {
      key: 'assignee',
      label: t('calendar.filters.assignee'),
      options: calendarResources.map(({ uid, fullName }) => ({ value: uid, label: fullName })),
      mode: 'multiple',
    },
  ];

  const startHour = dayjs(organisationData?.calendar.startTime.toDate()).hour();
  const endHour = dayjs(organisationData?.calendar.endTime.toDate()).hour();

  useEffect(() => {
    const calculateDefaultDay = (current: Dayjs) => {
      if ((organisationData?.calendar.enabledDays ?? [0, 1, 2, 3, 4, 5, 6]).includes(current.day())) {
        return current;
      } else {
        return calculateDefaultDay(current.add(1, 'day'));
      }
    };

    if (!date) {
      setSearchParams(
        (prev) => {
          const newDate = calculateDefaultDay(dayjs());
          prev.set('date', newDate.format('YYYY-MM-DD'));
          return prev;
        },
        { replace: true }
      );
    }
  }, [date, setSearchParams, organisationData?.calendar.enabledDays]);

  useEffect(() => {
    const calendarResources = calendarResourcesState?.data || [];
    const clinicsData = clinicsState?.data || [];

    const assigneeClinics = calendarResources
      .filter(({ uid }) => !activeFilters.assignee || activeFilters.assignee.includes(uid))
      .map(({ clinics }) => clinics)
      .flat();

    const clinics = clinicsData
      .filter(({ deleted }) => !deleted)
      .filter(({ uid }) => assigneeClinics.includes(uid))
      .map(({ uid, name }) => ({ value: uid, label: name }));

    setClinicList(clinics);
  }, [calendarResourcesState?.data, clinicsState?.data, activeFilters]);

  useMemo(() => {
    if (date) {
      const newStartDate = dayjs(date).startOf('week').startOf('day').toDate();

      if (!startDate || (startDate && newStartDate.getTime() !== startDate.getTime())) {
        setStartDate(newStartDate);
      }
    }
  }, [date, startDate]);

  useEffect(() => {
    const subscriptions: Unsubscribe[] = [];
    if ((calendarResourcesState?.data?.length ?? 0) > 0 && startDate) {
      setLoadingAppointments(true);
      setLoadingUnavailability(
        calendarResourcesState?.data?.reduce((acc, { uid }) => {
          if (!activeFilters.assignee || activeFilters.assignee.includes(uid)) {
            return { ...acc, [uid]: true };
          }
          return acc;
        }, {}) || {}
      );
      const baseConstraints = [
        where('organisationUid', '==', userData?.organisationUid),
        ...(activeFilters.assignee
          ? [where('assignee.uid', 'in', activeFilters.assignee)]
          : [
              where(
                'assignee.uid',
                'in',
                calendarResourcesState?.data.map((resource) => resource.uid)
              ),
            ]),
      ];

      const baseUnavailabilityConstraints = or(
        and(
          where('repeat.isRepeating', '==', false),
          or(
            and(
              where('startDateTime', '>=', dayjs(startDate).startOf('week').startOf('day').toDate()),
              where('startDateTime', '<=', dayjs(startDate).endOf('week').endOf('day').toDate())
            ),
            and(
              where('endDateTime', '>=', dayjs(startDate).startOf('week').startOf('day').toDate()),
              where('endDateTime', '<=', dayjs(startDate).endOf('week').endOf('day').toDate())
            ),
            and(
              where('startDateTime', '<=', dayjs(startDate).startOf('week').startOf('day').toDate()),
              where('endDateTime', '>=', dayjs(startDate).endOf('week').endOf('day').toDate())
            )
          )
        ),
        and(
          where('repeat.isRepeating', '==', true),
          where('startDateTime', '<=', dayjs(startDate).endOf('week').endOf('day').toDate()),
          where('endDateTime', '>=', dayjs(startDate).startOf('week').startOf('day').toDate())
        )
      );
      subscriptions.push(
        AppointmentsApiService.onCollectionSnapshot(
          (snap) => {
            setAppointments(snap.docs.map((doc) => doc.data()));
            setLoadingAppointments(false);
          },
          (error: FirestoreError, userData?: IUserDao) => {
            message.error(t('appointments.appointments_calendar.get_appointments_error'));
            sentryCaptureException(error, 'Appointments calendar fetching appointments', userData);
          },
          [
            ...baseConstraints,
            where('startDateTime', '>=', dayjs(startDate).startOf('week').startOf('day').toDate()),
            where('startDateTime', '<=', dayjs(startDate).endOf('week').endOf('day').toDate()),
            ...(activeFilters.clinic ? [where('clinic', 'in', activeFilters.clinic)] : []),
          ]
        )
      );

      if (
        [Permission.HOLIDAYS_READ, Permission.ORGANISATION_OWNER].some((permission) =>
          userPermissions?.includes(permission)
        )
      ) {
        let subscriptionError = false;
        const assigneeList = activeFilters.assignee || calendarResourcesState?.data.map(({ uid }) => uid) || [];
        assigneeList.forEach((uid) => {
          subscriptions.push(
            HolidayAndUnavailabilityApiService.onCollectionSnapshot(
              (snap) => {
                setAssigneeUnavailability((prev) => ({ ...prev, [uid]: snap.docs.map((doc) => doc.data()) }));
                setLoadingUnavailability((prev) => ({ ...prev, [uid]: false }));
              },
              (error: FirestoreError, userData?: IUserDao) => {
                sentryCaptureException(error, 'Appointments calendar fetching holidays', userData);
                if (!subscriptionError) {
                  message.error(t('appointments.appointments_calendar.get_holidays_error'));
                  subscriptionError = true;
                }
              },
              [],
              {
                filter: and(
                  where('organisationUid', '==', userData?.organisationUid),
                  where('assignee.uid', '==', uid),
                  baseUnavailabilityConstraints
                ),
                orderBy: orderBy('startDateTime'),
              }
            )
          );
        });
      } else if (userPermissions?.includes(Permission.HOLIDAYS_OWN)) {
        subscriptions.push(
          HolidayAndUnavailabilityApiService.onCollectionSnapshot(
            (snap) => {
              setAssigneeUnavailability((prev) => ({
                ...prev,
                [userData?.uid!]: snap.docs.map((doc) => doc.data()),
              }));
              setLoadingUnavailability((prev) => ({ ...prev, [userData?.uid!]: false }));
            },
            (error: FirestoreError, userData?: IUserDao) => {
              sentryCaptureException(error, 'Appointments calendar fetching holidays', userData);
              message.error(t('appointments.appointments_calendar.get_holidays_error'));
            },
            [],
            {
              filter: and(...baseConstraints, baseUnavailabilityConstraints),
              orderBy: orderBy('startDateTime'),
            }
          )
        );
      } else {
        setLoadingUnavailability({});
      }
    } else {
      setLoadingAppointments(false);
      setLoadingUnavailability({});
    }

    return () => {
      subscriptions.forEach((unsubscribe) => unsubscribe());
    };
  }, [
    activeFilters.assignee,
    activeFilters.clinic,
    calendarResourcesState?.data,
    calendarResourcesState?.data?.length,
    message,
    startDate,
    t,
    userData,
    userPermissions,
  ]);

  const paramChanged = (key: string, value: string) => {
    setSearchParams((prev) => {
      if (!value || value === '') {
        prev.delete(key);
      } else {
        prev.set(key, value);
      }

      return prev;
    });
  };

  const handleAddClick: MenuProps['onClick'] = async ({ key }) => {
    switch (key) {
      case 'appointment':
        dialog?.openDialog(
          <PatientSearchDialog onSelect={(patient) => navigate(`create?patient=${patient.objectID}&date=${date}`)} />
        );
        break;
      case 'patient':
        dialog?.openDialog(<AddEditPatientDialog navigateAfterSubmit />);
        break;
      case 'holiday':
        dialog?.openDialog(<AddEditHolidayUnavailabilityDialog tableKey='' initialDate={dayjs(date)} />);
    }
  };

  return (
    <div className='rounded-md bg-white shadow-md grow flex overflow-hidden my-4' ref={calendarWrapperRef}>
      <SharedCalendar
        filters={activeFilters}
        calendarWrapperRef={calendarWrapperRef}
        loading={loading}
        changeDate={(newDate) => paramChanged('date', newDate)}
        currentDate={dayjs(date)}
        timeSlots={getAppointment60MinuteTimeSlots(startHour, endHour)}
        appointments={appointments}
        unavailability={Object.values(assigneeUnavailability).flat()}
        people={calendarResourcesState?.data || []}
        startHour={startHour}
        extra={
          <div className='space-x-2 flex items-center'>
            <SharedElementPermissionGuard requiredPermissions={[[Permission.APPOINTMENTS_IMPORT]]}>
              <SharedButton
                onClick={() => dialog?.openDialog(<BulkAddAppointmentsDialog />)}
                type='button'
                labelKey='common.import'
              />
            </SharedElementPermissionGuard>
            <SharedButton
              icon={
                <Printer
                  size={20}
                  onClick={() =>
                    dialog?.openDialog(
                      <PrintScheduleDialog
                        date={date}
                        usersList={calendarResources.filter(
                          (resource) => !activeFilters.assignee || activeFilters.assignee.includes(resource.uid)
                        )}
                      />
                    )
                  }
                />
              }
            />
            <DrawerFilter filters={filters} onFilterChange={(filters) => setActiveFilters(filters)} />
            <Dropdown
              menu={{
                items: [
                  {
                    label: t('appointments.appointments_calendar.new.appointment'),
                    key: 'appointment',
                    requiredPermission: Permission.APPOINTMENTS_CREATE,
                  },
                  {
                    label: t('appointments.appointments_calendar.new.patient'),
                    key: 'patient',
                    requiredPermission: Permission.PATIENTS_CREATE,
                  },
                  {
                    label: t('appointments.appointments_calendar.new.holiday'),
                    key: 'holiday',
                    requiredPermission: Permission.HOLIDAYS_CREATE,
                  },
                ]
                  .filter(
                    ({ requiredPermission }) =>
                      userPermissions?.includes(Permission.ORGANISATION_OWNER) ||
                      userPermissions?.includes(requiredPermission)
                  )
                  .map(({ label, key }) => ({ label, key })),
                onClick: handleAddClick,
              }}
              trigger={['click']}
            >
              <div className='flex items-center'>
                <SharedButton appearance='primary' icon={<Plus size={22} />} />
              </div>
            </Dropdown>
          </div>
        }
      />
    </div>
  );
};

export default AppointmentsCalendar;
