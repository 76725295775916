import { useTranslation } from 'react-i18next';
import SharedButton from 'shared/button/button';
import SharedPageHeader from 'shared/page-header/page-header';
import { ISharedTableColumn, ISharedTableCustomTemplate } from 'shared/table/table.interface';
import SharedCard from 'shared/card/card';
import { CollectionID } from 'core/constants/collection-id';
import { where } from 'firebase/firestore';
import SharedPaginatedTable from 'shared/table/paginated-table';
import { useUserState } from 'core/providers/user-provider';
import SharedElementPermissionGuard from 'shared/permissions/element-permission-guard';
import { Permission } from 'core/constants/permission';
import { IHearingAidDao } from 'core/api/types';
import { useDialog } from 'core/providers/dialog-provider';
import AddEditHearingAidsDialog from './add-edit-hearing-aids-dialog';
import DeleteHearingAidsDialog from './delete-hearing-aids-dialog';
import BulkAddHearingAidsDialog from './bulk-add-hearing-aids-dialog';
import { OrganisationSettingsSlice } from 'modules/organisation-settings/organisation-settings-slice';
import { Dispatch, SetStateAction, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import SharedForm from 'shared/form/shared-form';
import { ControlType } from 'core/enums/control-type';

const tableColumns: ISharedTableColumn[] = [
  {
    labelKey: 'products_and_services.hearing_aids_list.table.header.manufacturer',
    key: 'manufacturer',
  },
  {
    labelKey: 'products_and_services.hearing_aids_list.table.header.model',
    key: 'model',
  },
  {
    labelKey: 'products_and_services.hearing_aids_list.table.header.style',
    key: 'style',
    width: 150,
  },
  {
    labelKey: 'products_and_services.hearing_aids_list.table.header.unit_cost',
    key: 'unitCost',
    width: 150,
  },
  {
    labelKey: 'products_and_services.hearing_aids_list.table.header.rrp',
    key: 'rrp',
    width: 100,
  },
  { key: 'action', contentTemplateId: 'actions', width: 120 },
];

const HearingAidsList = () => {
  const { t } = useTranslation();
  const { userData } = useUserState();
  const dialog = useDialog();
  const tableKey = 'products_and_services.hearing_aids_list.table';
  const [activeFilters, setActiveFilters] = useState<Record<string, string>>({});

  const headerActions = [
    {
      element: (
        <SharedElementPermissionGuard
          requiredPermissions={[[Permission.PRODUCTS_AND_SERVICES_CREATE], [Permission.ORGANISATION_OWNER]]}
        >
          <SharedButton
            labelKey='products_and_services.hearing_aids_list.header.bulk_add_hearing_aids'
            appearance='primary'
            onClick={() => dialog?.openDialog(<BulkAddHearingAidsDialog tableKey={tableKey} />)}
          />
        </SharedElementPermissionGuard>
      ),
      key: 'bulkAddHearingAids',
    },
    {
      element: (
        <SharedElementPermissionGuard
          requiredPermissions={[[Permission.PRODUCTS_AND_SERVICES_CREATE], [Permission.ORGANISATION_OWNER]]}
        >
          <SharedButton
            labelKey='products_and_services.hearing_aids_list.header.add_hearing_aid'
            appearance='primary'
            onClick={() => dialog?.openDialog(<AddEditHearingAidsDialog tableKey={tableKey} />)}
          />
        </SharedElementPermissionGuard>
      ),
      key: 'addHearingAid',
    },
  ];

  const actionTemplate = (hearingAid: IHearingAidDao) => {
    return (
      <div className='w-full flex justify-end space-x-4'>
        <SharedElementPermissionGuard
          requiredPermissions={[[Permission.PRODUCTS_AND_SERVICES_UPDATE], [Permission.ORGANISATION_OWNER]]}
        >
          <SharedButton
            onClick={() => dialog?.openDialog(<AddEditHearingAidsDialog existing={hearingAid} tableKey={tableKey} />)}
            type='button'
            appearance='link'
            labelKey='common.edit'
            primaryOverride
          />
        </SharedElementPermissionGuard>
        <SharedElementPermissionGuard
          requiredPermissions={[[Permission.PRODUCTS_AND_SERVICES_DELETE], [Permission.ORGANISATION_OWNER]]}
        >
          <SharedButton
            onClick={() => dialog?.openDialog(<DeleteHearingAidsDialog existing={hearingAid} tableKey={tableKey} />)}
            type='button'
            appearance='link'
            labelKey='common.delete'
            danger
          />
        </SharedElementPermissionGuard>
      </div>
    );
  };

  const contentTemplates: ISharedTableCustomTemplate[] = [
    {
      template: actionTemplate,
      id: 'actions',
    },
  ];

  return (
    <>
      <SharedPageHeader showBack title={t('products_and_services.hearing_aids')} actions={headerActions} />
      <HearingAidListFilter activeFilters={activeFilters} setActiveFilters={setActiveFilters} />
      <SharedCard>
        {userData && (
          <SharedPaginatedTable
            collectionId={CollectionID.HEARING_AIDS}
            queryConstraints={[
              where('organisationUid', '==', userData.organisationUid),
              where('deleted', '==', false),
              ...(activeFilters?.manufacturer ? [where('manufacturer', '==', activeFilters.manufacturer)] : []),
              ...(activeFilters?.model ? [where('model', '==', activeFilters.model)] : []),
            ]}
            queryOrder={['updated.at', 'desc']}
            tableConfig={{
              columns: tableColumns,
              contentTemplates: contentTemplates,
            }}
            errorMessageKey='products_and_services.hearing_aids_list.table.error.description'
            tableKey={tableKey}
          />
        )}
      </SharedCard>
    </>
  );
};

export default HearingAidsList;

interface IHearingAidListFilter {
  activeFilters: Record<string, string>;
  setActiveFilters: Dispatch<SetStateAction<Record<string, string>>>;
}

interface HearingAidListFilterFormOutput {
  manufacturer?: string;
  model?: string;
}

const HearingAidListFilter = ({ activeFilters, setActiveFilters }: IHearingAidListFilter) => {
  const { t } = useTranslation();
  const haringAids = useSelector(OrganisationSettingsSlice.selectHearingAids);
  const manufacturers = useMemo(
    () =>
      [
        ...new Set(
          haringAids?.data
            .filter(({ deleted, model }) => !deleted && (!activeFilters.model || activeFilters.model === model))
            .map(({ manufacturer }) => manufacturer)
        ),
      ].map((manufacturer) => ({ value: manufacturer, label: manufacturer })),
    [haringAids?.data, activeFilters.model]
  );
  const models = useMemo(
    () =>
      [
        ...new Set(
          haringAids?.data
            .filter(
              ({ deleted, manufacturer }) =>
                !deleted && (!activeFilters.manufacturer || activeFilters.manufacturer === manufacturer)
            )
            .map(({ model }) => model)
        ),
      ].map((model) => ({ value: model, label: model })),
    [haringAids?.data, activeFilters.manufacturer]
  );

  return (
    <SharedCard title={t('filters.title')}>
      <SharedForm<HearingAidListFilterFormOutput>
        className='overflow-x-auto py-4 pl-4'
        buttonsOverride={[]}
        requiredMark={false}
        layout='inline'
        onChange={(change) => {
          setActiveFilters((prevState) => ({ ...prevState, ...change }));
        }}
        name='finance-overview-filters'
        fields={[
          {
            fieldKey: 'manufacturer',
            control: ControlType.Select,
            placeholder: t('stock_management.hearing_aids.filters.manufacturer'),
            options: manufacturers,
            required: false,
            allowClear: true,
            className: 'min-w-[200px]',
          },
          {
            fieldKey: 'model',
            control: ControlType.Select,
            placeholder: t('stock_management.hearing_aids.filters.model'),
            options: models,
            required: false,
            className: 'min-w-[200px]',
            allowClear: true,
          },
        ]}
      />
    </SharedCard>
  );
};
