import { ISharedPaginatedTable } from './table.interface';
import {
  DocumentData,
  Query,
  QuerySnapshot,
  collection,
  endBefore,
  getDocs,
  limit,
  limitToLast,
  orderBy,
  query,
  startAfter,
} from 'firebase/firestore';
import { useCallback, useEffect, useMemo, useState } from 'react';
import SharedTable from './table';
import SharedButton from 'shared/button/button';
import { useTranslation } from 'react-i18next';
import { useComponentPortalState } from 'core/providers/component-portal-provider';
import { App } from 'antd';
import { firestore } from 'core/config/firebase';
import { sentryCaptureException } from 'shared/helpers/sentry-helpers';

const SharedPaginatedTable = ({
  tableKey,
  collectionId: collectionKey,
  queryConstraints,
  tableConfig,
  queryLimit = 10,
  queryOrder,
  errorMessageKey,
}: ISharedPaginatedTable) => {
  const collectionRef = useMemo(() => collection(firestore, collectionKey), [collectionKey]);
  const order = useMemo(() => orderBy(...queryOrder), [queryOrder]);
  const [cursor, setCursor] = useState(0);
  const [onLastPage, setOnLastPage] = useState(false);
  const [q, setQ] = useState<Query<DocumentData, DocumentData>>();
  const [querySnapshot, setQuerySnapshot] = useState<QuerySnapshot>();
  const [loading, setLoading] = useState(true);
  const { message } = App.useApp();
  const { t } = useTranslation();
  const { register, deregister } = useComponentPortalState();

  useEffect(() => {
    setQ(query(collectionRef, order, ...(queryConstraints ?? []), limit(queryLimit)));
    setCursor(0);
  }, [collectionRef, order, queryConstraints, queryLimit]);

  const getTableData = useCallback(async () => {
    if (q) {
      setLoading(true);
      try {
        const snapshot = await getDocs(q);
        setQuerySnapshot(snapshot);
        setLoading(false);
      } catch (error) {
        message.error(t(errorMessageKey));
        sentryCaptureException(error, 'Getting table data');
      }
    }
  }, [errorMessageKey, message, q, t]);

  useEffect(() => {
    register({ key: tableKey, exposedFunction: getTableData });

    return () => {
      deregister(tableKey);
    };
  }, [tableKey, getTableData, register, deregister]);

  useEffect(() => {
    getTableData();
  }, [getTableData]);

  useEffect(() => {
    const checkForMorePages = async () => {
      if (!querySnapshot) {
        return;
      }
      const { docs, size, empty } = querySnapshot;
      if (empty) {
        setOnLastPage(true);
        return;
      }
      try {
        const docCheck = await getDocs(
          query(collectionRef, order, ...(queryConstraints ?? []), startAfter(docs[size - 1]), limit(queryLimit))
        );
        if (docCheck.empty) {
          setOnLastPage(true);
        } else {
          setOnLastPage(false);
        }
      } catch (error) {
        message.error(t(errorMessageKey));
      }
    };
    checkForMorePages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [querySnapshot]);

  const previousPage = () => {
    if (!querySnapshot) {
      return;
    }
    const { docs } = querySnapshot;
    setQ(query(collectionRef, order, ...(queryConstraints ?? []), endBefore(docs[0]), limitToLast(queryLimit)));
    setCursor((prevState) => prevState - 1);
  };

  const nextPage = () => {
    if (!querySnapshot) {
      return;
    }
    const { docs, size } = querySnapshot;
    setQ(query(collectionRef, order, ...(queryConstraints ?? []), startAfter(docs[size - 1]), limit(queryLimit)));
    setCursor((prevState) => prevState + 1);
  };

  return (
    <>
      <SharedTable
        {...tableConfig}
        rows={
          querySnapshot?.docs.map((doc) => {
            return {
              key: doc.id,
              data: doc.data(),
            };
          }) ?? []
        }
        loading={loading}
      />

      <div className='p-3 border-t space-x-3 flex justify-end'>
        <SharedButton
          labelKey='common.previous'
          appearance='primary'
          disabled={cursor === 0 || loading}
          onClick={previousPage}
        />
        <SharedButton labelKey='common.next' appearance='primary' disabled={onLastPage || loading} onClick={nextPage} />
      </div>
    </>
  );
};

export default SharedPaginatedTable;
