import { useDialog } from 'core/providers/dialog-provider';
import { useState } from 'react';
import SharedDialogBase, { ISharedDialogButton } from 'shared/dialog/dialog-base';
import { App } from 'antd';
import { ISharedButton } from 'shared/button/button.interface';
import { useMultipleComponents } from 'core/providers/component-portal-provider';
import { useUserState } from 'core/providers/user-provider';
import { sentryCaptureException } from 'shared/helpers/sentry-helpers';

interface IConfirmActionDialog {
  action: () => Promise<void>;
  actionButtonProps?: ISharedButton;
  title: string;
  textContent: string;
  successMessage: string;
  errorMessage: string;
  componentPortalKeys?: string[];
}

const ConfirmActionDialog = ({
  action,
  actionButtonProps,
  title,
  textContent,
  successMessage,
  errorMessage,
  componentPortalKeys = [],
}: IConfirmActionDialog) => {
  const [loading, setLoading] = useState(false);
  const dialog = useDialog();
  const { message } = App.useApp();
  const tables = useMultipleComponents(componentPortalKeys);
  const { userData } = useUserState();

  const customButtons: ISharedDialogButton[] = [
    {
      key: 'cancel',
      props: {
        onClick: () => dialog?.closeDialog(),
        appearance: 'text',
        labelKey: 'common.cancel',
      },
    },
    {
      key: 'confirmAction',
      props: {
        ...(actionButtonProps ?? {
          labelKey: 'common.confirm',
          danger: false,
        }),
        appearance: 'primary',
        loading,
        onClick: async () => {
          setLoading(true);
          try {
            await action();
            dialog?.closeDialog();
            message.success(successMessage);
            tables.forEach((table) => table?.exposedFunction());
          } catch (error) {
            message.error(errorMessage);
            setLoading(false);
            sentryCaptureException(error, title, userData);
          }
        },
      },
    },
  ];

  return <SharedDialogBase title={title} textContent={textContent} customButtons={customButtons} />;
};

export default ConfirmActionDialog;
