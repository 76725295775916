import dayjs, { Dayjs } from 'dayjs';
import { Timestamp } from 'firebase/firestore';
import _ from 'lodash';

export const getAppointment15MinuteTimeSlots = (calendarStart: Dayjs, calendarEnd: Dayjs) => {
  const startHour = calendarStart.hour();
  const startMinute = calendarStart.minute();
  const endHour = calendarEnd.hour();
  const endMinute = calendarEnd.minute();
  const hours = [...Array(24).keys()];
  const workingHours = hours.filter((hour) => hour >= startHour && hour <= endHour);
  const timeSlots = workingHours.flatMap((hour, index) => {
    let h = hour.toString();
    if (hour < 10) {
      h = `0${hour}`;
    }
    let hours = [`${h}:00`, `${h}:15`, `${h}:30`, `${h}:45`];

    if (index === 0) {
      return hours.filter((hour) => parseInt(hour.split(':')[1]) >= startMinute);
    } else if (index === workingHours.length - 1) {
      return hours.filter((hour) => parseInt(hour.split(':')[1]) <= endMinute);
    } else {
      return hours;
    }
  });
  return timeSlots;
};

export const getAppointment60MinuteTimeSlots = (startHour: number, endHour: number) => {
  const hours = [...Array(24).keys()];
  const workingHours = hours.filter((hour) => hour >= startHour && hour <= endHour);

  const timeSlots = workingHours.map((hour) => {
    let h = hour.toString();
    if (hour < 10) {
      h = `0${hour}`;
    }
    return `${h}:00`;
  });
  return timeSlots;
};

const getHourAndMinuteFromTimeString = (timeString: string) => {
  const [hour, minute] = timeString.split(':');
  return { hour: parseInt(hour), minute: parseInt(minute) };
};

export const getTimestampFromDateAndTimeString = (date: Dayjs, timeString: string) => {
  const { hour, minute } = getHourAndMinuteFromTimeString(timeString);
  const clonedDate = _.cloneDeep(date);
  return Timestamp.fromDate(clonedDate.hour(hour).minute(minute).second(0).toDate());
};

export const getDayJsFromDateAndTimeString = (date: Dayjs, timeString: string) => {
  const { hour, minute } = getHourAndMinuteFromTimeString(timeString);
  const clonedDate = _.cloneDeep(date);
  return clonedDate.hour(hour).minute(minute).second(0);
};

export const getTimeStringFromTimestamp = (timestamp: Timestamp) => {
  return dayjs(timestamp.toDate()).format('HH:mm');
};

const zeroTheDate = (date: Dayjs) => date.year(0).month(0).date(1);

export const getAppointmentStyle = (
  calendarStart: Dayjs,
  start: Dayjs,
  end: Dayjs,
  cancelled: boolean,
  widthPercent: number,
  leftPercent: number,
  timeSlotHeight: number,
  backgroundColour: string
) => {
  const hourMargin = (start.hour() - calendarStart.hour()) * 12;
  const minuteMargin = start.minute() / 5;
  const fiveMinuteSlotHeight = timeSlotHeight / 12;
  const slotHeight = zeroTheDate(end).diff(zeroTheDate(start), 'minute') / 5;
  const style = {
    top: `${(hourMargin + minuteMargin) * fiveMinuteSlotHeight}px`,
    height: `${slotHeight * fiveMinuteSlotHeight}px`,
    width: `${widthPercent * 100}%`,
    left: `${leftPercent * 100}%`,
    background: cancelled
      ? 'repeating-linear-gradient(45deg, #dc2626, #dc2626 10px, #b91c1c 10px, #b91c1c 20px)'
      : backgroundColour,
  };
  return style;
};
export const getCalendarHours = (startHour: number, endHour: number) => {
  const all = Array.from<number>({ length: 24 }).map((_, i) => i);
  const enabled: number[] = [];
  const disabled: number[] = [];
  all.forEach((i) => {
    if (i < startHour || i > endHour) {
      disabled.push(i);
    } else {
      enabled.push(i);
    }
  });
  return {
    enabled,
    disabled,
  };
};
