import { CustomFormTemplatesApiService } from 'core/api';
import { ICustomFormTemplateDao } from 'core/api/types/custom-form-templates.interface';
import { Permission } from 'core/constants/permission';
import { useDialog } from 'core/providers/dialog-provider';
import { useUserState } from 'core/providers/user-provider';
import { Timestamp } from 'firebase/firestore';
import { OrganisationSettingsSlice } from 'modules/organisation-settings/organisation-settings-slice';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import SharedButton from 'shared/button/button';
import SharedCard from 'shared/card/card';
import ConfirmActionDialog from 'shared/dialog/confirm-action-dialog';
import { getActionTimestampFromUser } from 'shared/helpers/user-action.helpers';
import SharedPageHeader from 'shared/page-header/page-header';
import SharedElementPermissionGuard from 'shared/permissions/element-permission-guard';
import SharedTable from 'shared/table/table';
import { ISharedTableColumn, ISharedTableCustomTemplate } from 'shared/table/table.interface';

const tableColumns: ISharedTableColumn[] = [
  {
    labelKey: 'form_settings.custom_forms_list.name',
    key: 'name',
  },
  {
    labelKey: 'form_settings.custom_forms_list.last_updated',
    key: 'lastUpdated',
    contentTemplateId: 'lastUpdated',
  },
  {
    key: 'actions',
    contentTemplateId: 'actions',
  },
];

const CustomFormsList = () => {
  const { t } = useTranslation();
  const { userData } = useUserState();
  const tableKey = 'form_settings.custom_forms_list.table';
  const dialog = useDialog();
  const customForms = useSelector(OrganisationSettingsSlice.selectCustomFormTemplates);

  const actionTemplate = (customForm: ICustomFormTemplateDao) => {
    return (
      <div className='w-full flex justify-end space-x-4'>
        <SharedElementPermissionGuard
          requiredPermissions={[[Permission.FORM_SETTINGS_UPDATE], [Permission.ORGANISATION_OWNER]]}
        >
          <Link to={`edit/${customForm.uid}`}>
            <SharedButton type='button' appearance='link' labelKey='common.edit' primaryOverride />
          </Link>
        </SharedElementPermissionGuard>
        <SharedElementPermissionGuard
          requiredPermissions={[[Permission.ORDERS_DELETE], [Permission.ORGANISATION_OWNER]]}
        >
          <SharedButton
            labelKey='common.delete'
            appearance='link'
            danger
            onClick={() =>
              dialog?.openDialog(
                <ConfirmActionDialog
                  action={() =>
                    CustomFormTemplatesApiService.update(customForm.uid, {
                      deleted: true,
                      updated: getActionTimestampFromUser(userData),
                    })
                  }
                  actionButtonProps={{
                    labelKey: 'common.delete',
                    danger: true,
                  }}
                  title={t('form_settings.custom_forms.delete_form.title')}
                  textContent={t('form_settings.custom_forms.delete_form.content')}
                  successMessage={t('form_settings.custom_forms.delete_form.success')}
                  errorMessage={t('form_settings.custom_forms.delete_form.error')}
                  componentPortalKeys={[tableKey]}
                />
              )
            }
          />
        </SharedElementPermissionGuard>
      </div>
    );
  };

  const contentTemplates: ISharedTableCustomTemplate[] = [
    {
      template: actionTemplate,
      id: 'actions',
    },
  ];

  const headerActions = [
    {
      element: (
        <SharedElementPermissionGuard
          requiredPermissions={[[Permission.FORM_SETTINGS_CREATE], [Permission.ORGANISATION_OWNER]]}
        >
          <Link to={'create'}>
            <SharedButton labelKey='form_settings.custom_forms.header.add_custom_form' appearance='primary' />
          </Link>
        </SharedElementPermissionGuard>
      ),
      key: 'add_custom_form',
    },
  ];

  return (
    <>
      <SharedPageHeader title={t('form_settings.custom_forms')} showBack actions={headerActions} />
      <SharedCard>
        <SharedTable
          rows={
            customForms?.data
              .filter((form) => !form.deleted)
              .map((form) => ({
                key: form.uid,
                data: {
                  ...form,
                  updated: {
                    at: Timestamp.fromMillis(form.updatedAtSeconds * 1000),
                    by: form.updatedBy,
                  },
                },
              })) ?? []
          }
          columns={tableColumns}
          contentTemplates={contentTemplates}
        />
      </SharedCard>
    </>
  );
};

export default CustomFormsList;
