import { ControlType } from 'core/enums/control-type';
import { useDialog } from 'core/providers/dialog-provider';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SharedDialogBase from 'shared/dialog/dialog-base';
import { useUserState } from 'core/providers/user-provider';
import { IAccessoryDao } from 'core/api/types';
import SharedForm from 'shared/form/shared-form';
import { ISharedField } from 'shared/fields/shared-fields.interface';
import { App, Form } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import { sentryCaptureException } from 'shared/helpers/sentry-helpers';
import { ServicesApiService } from 'core/api';
import { getDownloadURL, ref } from 'firebase/storage';
import { storage } from 'core/config/firebase';
import SharedButton from 'shared/button/button';
import { useAComponentPortal } from 'core/providers/component-portal-provider';
import SharedTable from 'shared/table/table';

interface IFailedRow {
  reason: string;
  data: string[];
}

const BulkAddServicesDialog = ({ tableKey }: { tableKey: string }) => {
  const { userData } = useUserState();
  const { message } = App.useApp();
  const { t } = useTranslation();
  const dialog = useDialog();
  const [form] = Form.useForm();
  const table = useAComponentPortal(tableKey);

  const uploadedFiles = useWatch('fileCapture', form);

  const [submitting, setSubmitting] = useState(false);
  const [uploadDataUrl, setUploadDataUrl] = useState<string | ArrayBuffer | null>();
  const [importResults, setImportResults] = useState<{
    successRows: string[];
    failedRows: IFailedRow[];
  }>({ successRows: [], failedRows: [] });
  const [template, setTemplate] = useState<string>();

  useEffect(() => {
    const getTemplate = async () => {
      const template = await getDownloadURL(ref(storage, `templates/services.csv`));

      setTemplate(template);
    };
    getTemplate();
  }, []);

  const reader = useMemo(() => {
    return new FileReader();
  }, []);

  useEffect(() => {
    if (uploadedFiles) {
      if (uploadedFiles.length === 0) {
        setUploadDataUrl(undefined);
      } else {
        reader.onloadend = () => {
          setUploadDataUrl(reader.result);
        };
        reader.readAsDataURL(uploadedFiles[0]);
      }
    }
  }, [uploadedFiles, reader]);

  const BulkAddServicesFormFields: ISharedField[] = [
    {
      fieldKey: 'fileCapture',
      control: ControlType.File,
      label: 'File',
      required: true,
      maxCount: 1,
      maxFileSize: 500000,
      acceptedFileTypes: ['text/csv'],
    },
  ];

  const submit = async (data: any) => {
    setSubmitting(true);
    try {
      if (!userData?.organisationUid) {
        throw new Error(t('auth.user.error'));
      }

      const { successRows, failedRows } = (await ServicesApiService.import(
        userData.organisationUid,
        uploadDataUrl!.toString()
      )) as {
        successRows: string[];
        failedRows: { reason: string; data: string[] }[];
      };

      setImportResults({ successRows, failedRows });

      if (!successRows.length && failedRows.length > 0) {
        message.error(t('dialog.bulk_add_services.error.description', { count: failedRows.length }));
      } else if (failedRows.length > 0 && successRows.length > 0) {
        message.success(t('dialog.bulk_add_services.success.description', { count: successRows.length }));
        message.error(t('dialog.bulk_add_services.error.description', { count: failedRows.length }));
      } else if (successRows.length > 0 && !failedRows.length) {
        message.success(t('dialog.bulk_add_services.success.description', { count: successRows.length }));
        dialog?.closeDialog();
        table?.exposedFunction();
      }
    } catch (error) {
      message.error(t('dialog.bulk_add_services.error.description'));
      setSubmitting(false);
      sentryCaptureException(error, 'Bulk Add Accessory', userData);
    }
  };

  const customContent = () => {
    return (
      <>
        <SharedForm<IAccessoryDao>
          formInstance={form}
          className='overflow-y-auto p-4'
          onFinish={submit}
          fields={BulkAddServicesFormFields}
          submitting={submitting}
          cancelButton={{ labelKey: 'common.cancel', appearance: 'text', onClick: () => dialog?.closeDialog() }}
          name='bulk-add-services'
        />
      </>
    );
  };

  const resultContent = () => {
    return (
      <SharedTable
        headerBackgroundColor='#f8fafc'
        rows={importResults.failedRows.map((row, index) => ({
          key: index.toString(),
          data: row,
        }))}
        contentTemplates={[
          {
            id: 'data',
            template: (failure: IFailedRow) => {
              return <p className='body-sm whitespace-pre-wrap'>{failure.data.join(', ')}</p>;
            },
          },
        ]}
        columns={[
          {
            width: 50,
            labelKey: 'dialog.bulk_add_hearing_aids.columns.row',
            key: 'reason',
          },
          {
            labelKey: 'dialog.bulk_add_hearing_aids.columns.errors',
            key: 'data',
            contentTemplateId: 'data',
          },
        ]}
      />
    );
  };

  return (
    <SharedDialogBase
      title={t('dialog.bulk_add_services.title')}
      extra={<SharedButton href={template} appearance='dashed' labelKey='dialog.bulk.download_template' />}
      customContentTemplate={importResults.failedRows.length ? resultContent() : customContent()}
      showButtons={importResults.failedRows.length ? true : false}
    />
  );
};

export default BulkAddServicesDialog;
