import { Dropdown, MenuProps } from 'antd';
import { EllipsisOutlined } from '@ant-design/icons';
import SharedButton from 'shared/button/button';
import { useDialog } from 'core/providers/dialog-provider';
import { useTranslation } from 'react-i18next';
import { Permission } from 'core/constants/permission';
import { usePermissionsState } from 'core/providers/permissions-provider';
import { IHolidayAndUnavailabilityDao } from 'core/api/types/holiday-and-unavailability.interface';
import { useTheme } from 'core/providers/theme-provider';
import AddEditHolidayUnavailabilityDialog from 'modules/organisation-settings/holidays-and-unavailability/add-edit-holiday-unavailability-dialog';
import ConfirmActionDialog from 'shared/dialog/confirm-action-dialog';
import { HolidayAndUnavailabilityApiService } from 'core/api';

interface IUnavailabilityMenu {
  unavailability: IHolidayAndUnavailabilityDao;
  className?: string;
}

const UnavailabilityMenu = ({ unavailability, className }: IUnavailabilityMenu) => {
  const { t } = useTranslation();
  const dialog = useDialog();
  const { userPermissions } = usePermissionsState();
  const { primary } = useTheme();
  const items = [
    {
      key: 'edit',
      label: t('common.edit'),
      requiredPermissions: [Permission.HOLIDAYS_UPDATE],
    },
    {
      key: 'delete',
      label: t('common.delete'),
      requiredPermissions: [Permission.HOLIDAYS_DELETE],
    },
  ];

  const onClick: MenuProps['onClick'] = ({ key }) => {
    switch (key) {
      case 'edit':
        dialog?.openDialog(<AddEditHolidayUnavailabilityDialog existingItem={unavailability} tableKey={''} />);
        break;
      case 'delete':
        dialog?.openDialog(
          <ConfirmActionDialog
            action={() => HolidayAndUnavailabilityApiService.permDelete(unavailability.uid)}
            actionButtonProps={{
              labelKey: 'common.delete',
              danger: true,
            }}
            title={t('holidays_and_unavailability.list.delete.title')}
            textContent={t('holidays_and_unavailability.list.delete.content')}
            successMessage={t('holidays_and_unavailability.list.delete.success')}
            errorMessage={t('holidays_and_unavailability.list.delete.error')}
          />
        );
        break;
      default:
        break;
    }
  };

  return (
    <div className={className}>
      <Dropdown
        menu={{
          items: items
            .filter((item) =>
              [[Permission.ORGANISATION_OWNER], item.requiredPermissions].some((permissions) =>
                permissions.every((permission) => userPermissions?.includes(permission))
              )
            )
            .map(({ requiredPermissions, ...rest }) => rest),
          onClick,
        }}
        placement='bottomRight'
        trigger={['click']}
      >
        <div>
          <SharedButton
            additionalStyle={{ color: primary.bg }}
            appearance='link'
            icon={<EllipsisOutlined size={20} />}
          />
        </div>
      </Dropdown>
    </div>
  );
};

export default UnavailabilityMenu;
