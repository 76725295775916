import { Form } from 'antd';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import SharedButton from 'shared/button/button';

interface ISharedSignatureField {
  id?: string;
  onChange?: (value?: SignatureCanvas | null) => void;
  disabled?: boolean;
  dataUrl?: string;
  onClear?: () => void;
}

const SharedSignatureField = ({ id, onChange, disabled, dataUrl, onClear }: ISharedSignatureField) => {
  const { status } = Form.Item.useStatus();
  const [signature, setSignature] = useState<SignatureCanvas | null>(null);

  useEffect(() => {
    const setImage = async (dataUrl: string) => {
      const img = new Image();
      img.src = dataUrl;
      img.onload = () => {
        let multiplier = 1;
        const maxH = 160;
        const maxW = 600;
        const width = img.width;
        const height = img.height;
        if (height > maxH) {
          multiplier = maxH / height;
        }

        if (width > maxW) {
          const m = maxW / width;
          multiplier = m < multiplier ? m : multiplier;
        }

        signature?.fromDataURL(dataUrl, {
          height: height * multiplier,
          width: width * multiplier,
        });
      };
    };

    if (signature) {
      onChange?.(signature);
      if (disabled) {
        signature.off();
      } else {
        signature.on();
      }
      if (dataUrl) {
        setImage(dataUrl);
      }
    }
  }, [dataUrl, disabled, onChange, signature]);

  return (
    <div className='relative'>
      <SharedButton
        className='absolute bottom-2 right-2'
        labelKey='field.signature.clear'
        appearance='link'
        primaryOverride
        onClick={() => {
          signature?.clear();
          onClear?.();
        }}
      />
      <SignatureCanvas
        clearOnResize={false}
        penColor='black'
        canvasProps={{
          className: clsx(
            'border w-full h-[200px]',
            status === 'error' && 'border-red-500',
            disabled && 'cursor-not-allowed bg-black/[0.04]'
          ),
        }}
        ref={(ref) => setSignature(ref)}
      />
    </div>
  );
};

export default SharedSignatureField;
