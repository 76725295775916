import { UsersApiService } from 'core/api';
import { UserType, UserTypeSelectOptions } from 'core/constants/user-type';
import { ControlType } from 'core/enums/control-type';
import { InputType } from 'core/enums/input-type';
import { useDialog } from 'core/providers/dialog-provider';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import SharedDialogBase from 'shared/dialog/dialog-base';
import NewUserPasswordDialog from 'shared/dialog/new-user-password-dialog';
import { OrganisationsSlice } from '../organisations/admin-organisations-slice';
import { ISharedField } from 'shared/fields/shared-fields.interface';
import SharedForm from 'shared/form/shared-form';
import { App, Form } from 'antd';
import { IUserDao } from 'core/api/types';
import { getActionTimestampFromUser } from 'shared/helpers/user-action.helpers';
import { useUserState } from 'core/providers/user-provider';
import { useAComponentPortal } from 'core/providers/component-portal-provider';

const userTypeFieldKey = 'userType';
const emailFieldKey = 'emailAddress';
const fullNameFieldKey = 'fullName';
const organisationFieldKey = 'organisationUid';

interface IAdminAddEditUserFormOutput {
  [userTypeFieldKey]: string;
  [emailFieldKey]: string;
  [fullNameFieldKey]: string;
  [organisationFieldKey]: string;
}

interface IAdminAddEditUserDialog {
  user?: IUserDao;
  tableKey: string;
}

const AdminAddEditUserDialog = ({ user, tableKey }: IAdminAddEditUserDialog) => {
  const { t } = useTranslation();
  const { data, status } = useSelector(OrganisationsSlice.selectState);
  const [submitting, setSubmitting] = useState(false);
  const creating = !user;
  const dialog = useDialog();
  const table = useAComponentPortal(tableKey);
  const { message } = App.useApp();
  const [form] = Form.useForm();
  const userType = Form.useWatch(userTypeFieldKey, form);
  const { userData } = useUserState();

  const AdminAddEditUserFormFields: ISharedField[] = [
    {
      fieldKey: userTypeFieldKey,
      control: ControlType.Select,
      options: UserTypeSelectOptions.map((op) => ({
        label: t(op.labelKey),
        value: op.value,
      })),
      label: t('common.user_type'),
      required: true,
      hidden: !creating,
    },
    {
      fieldKey: organisationFieldKey,
      control: ControlType.Select,
      options: data.map((org) => ({
        label: org.name,
        value: org.uid,
      })),
      loading: status !== 'success',
      label: t('common.organisation'),
      required: true,
      hidden: !creating || !userType || userType === UserType.HEARLINK_ADMIN,
    },
    {
      fieldKey: fullNameFieldKey,
      control: ControlType.TextField,
      type: InputType.Text,
      label: t('common.full_name'),
      required: true,
    },
    {
      fieldKey: emailFieldKey,
      control: ControlType.TextField,
      type: InputType.Email,
      label: t('common.email_address'),
      required: true,
    },
  ];

  const addUser = async (data: IAdminAddEditUserFormOutput) => {
    setSubmitting(true);
    try {
      const { fullName, emailAddress, organisationUid } = data;
      let temporaryPassword: string = '';
      switch (data[userTypeFieldKey]) {
        case UserType.HEARLINK_ADMIN:
          temporaryPassword = await UsersApiService.createAdmin({
            fullName,
            emailAddress,
          });
          break;
        case UserType.ORGANISATION_OWNER:
          temporaryPassword = await UsersApiService.createOrganisationOwner({
            fullName,
            emailAddress,
            organisationUid,
          });
          break;
        case UserType.MEMBER:
          temporaryPassword = await UsersApiService.createOrganisationMember({
            fullName,
            emailAddress,
            organisationUid,
          });
          break;
        default:
          throw new Error('Invalid user type');
      }
      dialog?.replaceDialog(
        <NewUserPasswordDialog
          fullName={data.fullName}
          emailAddress={data.emailAddress}
          temporaryPassword={temporaryPassword}
        />
      );
      table?.exposedFunction();
    } catch (error) {
      message.error(t('dialog.add_edit_user.create.error.description'));
      setSubmitting(false);
    }
  };

  const editUser = async (data: IAdminAddEditUserFormOutput) => {
    try {
      setSubmitting(true);
      if (!user || !userData) {
        throw new Error('User not found');
      }

      await UsersApiService.update(user.uid, {
        [emailFieldKey]: data[emailFieldKey],
        [fullNameFieldKey]: data[fullNameFieldKey],
        updated: getActionTimestampFromUser(userData),
      });
      dialog?.closeDialog();
      message.success(t('dialog.add_edit_user.edit.success.description'));
      table?.exposedFunction();
    } catch (error) {
      message.error(t('dialog.add_edit_user.edit.error.description'));
      setSubmitting(false);
    }
  };

  const customContent = () => {
    return (
      <SharedForm<IAdminAddEditUserFormOutput>
        formInstance={form}
        onFinish={(data) => (creating ? addUser(data) : editUser(data))}
        fields={AdminAddEditUserFormFields}
        submitting={submitting}
        cancelButton={{ labelKey: 'common.cancel', appearance: 'text', onClick: () => dialog?.closeDialog() }}
        name='admin-add-edit-user-form'
        existingValue={
          user && {
            [emailFieldKey]: user.emailAddress,
            [fullNameFieldKey]: user.fullName,
          }
        }
      />
    );
  };

  return (
    <SharedDialogBase
      title={t(creating ? 'dialog.add_edit_user.create.title' : 'dialog.add_edit_user.edit.title')}
      customContentTemplate={customContent()}
      showButtons={false}
    />
  );
};

export default AdminAddEditUserDialog;
