import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  IClinicDao,
  IFormSettingsDao,
  ILeadTypeDao,
  IUploadTypeDao,
  IAppointmentTypeDao,
  IAudiometerDao,
  IHearingAidDao,
  IAccessoryDao,
  IServiceDao,
  IPatientDao,
} from 'core/api/types';
import { IWorkflowDao } from 'core/api/types/workflow.interface';
import { IMetaDao } from 'core/api/types/meta.interface';
import { IListDataSlice, IObjectDataSlice, StoreState } from 'store';
import { IAddressDao } from 'shared/interfaces/address.interface';
import { ICustomFormTemplateDao } from 'core/api/types/custom-form-templates.interface';
import { IUserWorkingLocationOverrideDao, IUserWorkingLocationsDao } from 'core/api/types/users.interface';
import { IPatientAlertDao } from 'core/api/types/patient-alert.interface';
export type IDomainOrganisationFormSettings = Omit<IFormSettingsDao, 'updated'> & {
  updatedAtSeconds: number;
};

export type IDomainOrganisationDataType<T> = Omit<T, 'updated' | 'created'> & {
  updatedAtSeconds: number;
  createdAtSeconds: number;
  updatedBy: string;
  createdBy: string;
};

export interface IDomainCalendarResource {
  uid: string;
  fullName: string;
  clinics: string[];
  assignableAppointmentTypes: string[];
  workingAreas?: string[];
  homeAddress?: IAddressDao;
  workingLocations?: IUserWorkingLocationsDao;
  workingLocationOverrides?: IUserWorkingLocationOverrideDao;
}

export interface IOrganisationSettings {
  formSettings: {
    patient?: IObjectDataSlice<IDomainOrganisationFormSettings>;
    order?: IObjectDataSlice<IDomainOrganisationFormSettings>;
  };
  leadTypes: IListDataSlice<IDomainOrganisationDataType<ILeadTypeDao>>;
  patientWorkflow: IObjectDataSlice<IDomainOrganisationDataType<IWorkflowDao>>;
  meta: IObjectDataSlice<IDomainOrganisationDataType<IMetaDao>>;
  clinics: IListDataSlice<IDomainOrganisationDataType<IClinicDao>>;
  calendarResources: IListDataSlice<IDomainCalendarResource>;
  appointmentTypes: IListDataSlice<IDomainOrganisationDataType<IAppointmentTypeDao>>;
  uploadTypes: IListDataSlice<IDomainOrganisationDataType<IUploadTypeDao>>;
  audiometers: IListDataSlice<IDomainOrganisationDataType<IAudiometerDao>>;
  hearingAids: IListDataSlice<IDomainOrganisationDataType<IHearingAidDao>>;
  accessories: IListDataSlice<IDomainOrganisationDataType<IAccessoryDao>>;
  services: IListDataSlice<IDomainOrganisationDataType<IServiceDao>>;
  customFormTemplates: IListDataSlice<IDomainOrganisationDataType<ICustomFormTemplateDao>>;
  patientAlerts: IListDataSlice<IDomainPatientAlert>;
}

export type IDomainPatientAlert = Omit<IDomainOrganisationDataType<IPatientAlertDao>, 'alertDateTime' | 'patient'> & {
  alertDateTimeSeconds: number;
  patient: IDomainOrganisationDataType<IPatientDao>;
};

const initialState: Partial<IOrganisationSettings> = {
  leadTypes: {
    status: 'loading',
    data: [],
  },
  clinics: {
    status: 'loading',
    data: [],
  },
  calendarResources: {
    status: 'loading',
    data: [],
  },
  appointmentTypes: { status: 'loading', data: [] },
  uploadTypes: {
    status: 'loading',
    data: [],
  },
  audiometers: {
    status: 'loading',
    data: [],
  },
  hearingAids: {
    status: 'loading',
    data: [],
  },
  accessories: {
    status: 'loading',
    data: [],
  },
  services: {
    status: 'loading',
    data: [],
  },
  customFormTemplates: {
    status: 'loading',
    data: [],
  },
  patientAlerts: {
    status: 'loading',
    data: [],
  },
};

const slice = createSlice({
  name: 'organisationSettings',
  initialState: initialState,
  reducers: {
    reset: () => initialState,
    updatePatientFormSettings(state, action: PayloadAction<IObjectDataSlice<IDomainOrganisationFormSettings>>) {
      state.formSettings = {
        ...state.formSettings,
        patient: action.payload,
      };
    },
    updateLeadTypes(state, action: PayloadAction<IListDataSlice<IDomainOrganisationDataType<ILeadTypeDao>>>) {
      state.leadTypes = action.payload;
    },
    updateOrderFormSettings(state, action: PayloadAction<IObjectDataSlice<IDomainOrganisationFormSettings>>) {
      state.formSettings = {
        ...state.formSettings,
        order: action.payload,
      };
    },
    updatePatientWorkflow(state, action: PayloadAction<IObjectDataSlice<IDomainOrganisationDataType<IWorkflowDao>>>) {
      state.patientWorkflow = action.payload;
    },
    updateMeta(state, action: PayloadAction<IObjectDataSlice<IDomainOrganisationDataType<IMetaDao>>>) {
      state.meta = action.payload;
    },
    updateClinics(state, action: PayloadAction<IListDataSlice<IDomainOrganisationDataType<IClinicDao>>>) {
      state.clinics = action.payload;
    },
    updateCalendarResources(state, action: PayloadAction<IListDataSlice<IDomainCalendarResource>>) {
      state.calendarResources = action.payload;
    },
    updateAppointmentTypes(
      state,
      action: PayloadAction<IListDataSlice<IDomainOrganisationDataType<IAppointmentTypeDao>>>
    ) {
      state.appointmentTypes = action.payload;
    },
    updateUploadTypes(state, action: PayloadAction<IListDataSlice<IDomainOrganisationDataType<IUploadTypeDao>>>) {
      state.uploadTypes = action.payload;
    },
    updateAudiometers(state, action: PayloadAction<IListDataSlice<IDomainOrganisationDataType<IAudiometerDao>>>) {
      state.audiometers = action.payload;
    },
    updateHearingAids(state, action: PayloadAction<IListDataSlice<IDomainOrganisationDataType<IHearingAidDao>>>) {
      state.hearingAids = action.payload;
    },
    updateAccessories(state, action: PayloadAction<IListDataSlice<IDomainOrganisationDataType<IAccessoryDao>>>) {
      state.accessories = action.payload;
    },
    updateServices(state, action: PayloadAction<IListDataSlice<IDomainOrganisationDataType<IServiceDao>>>) {
      state.services = action.payload;
    },
    updateCustomFormTemplates(
      state,
      action: PayloadAction<IListDataSlice<IDomainOrganisationDataType<ICustomFormTemplateDao>>>
    ) {
      state.customFormTemplates = action.payload;
    },
    updatePatientAlerts(state, action: PayloadAction<IListDataSlice<IDomainPatientAlert>>) {
      state.patientAlerts = action.payload;
    },
  },
});

const {
  reset,
  updatePatientFormSettings,
  updateLeadTypes,
  updateOrderFormSettings,
  updatePatientWorkflow,
  updateMeta,
  updateClinics,
  updateCalendarResources,
  updateAppointmentTypes,
  updateUploadTypes,
  updateAudiometers,
  updateHearingAids,
  updateAccessories,
  updateServices,
  updateCustomFormTemplates,
  updatePatientAlerts,
} = slice.actions;

const selectState = (state: StoreState) => state.organisationSettings;
const selectPatientFormSettings = (state: StoreState) => state.organisationSettings.formSettings?.patient;
const selectLeadTypes = (state: StoreState) => state.organisationSettings.leadTypes;
const selectOrderFormSettings = (state: StoreState) => state.organisationSettings.formSettings?.order;
const selectPatientWorkflow = (state: StoreState) => state.organisationSettings.patientWorkflow;
const selectPatientWorkflowStatuses = (state: StoreState) => state.organisationSettings.patientWorkflow?.data?.statuses;
const selectMeta = (state: StoreState) => state.organisationSettings.meta;
const selectClinics = (state: StoreState) => state.organisationSettings.clinics;
const selectCalendarResources = (state: StoreState) => state.organisationSettings.calendarResources;
const selectAppointmentTypes = (state: StoreState) => state.organisationSettings.appointmentTypes;
const selectUploadTypes = (state: StoreState) => state.organisationSettings.uploadTypes;
const selectClinic = (state: StoreState, clinicUid: string) =>
  state.organisationSettings.clinics?.data.find((c) => c.uid === clinicUid);
const selectAudiometers = (state: StoreState) => state.organisationSettings.audiometers;
const selectHearingAids = (state: StoreState) => state.organisationSettings.hearingAids;
const selectAccessories = (state: StoreState) => state.organisationSettings.accessories;
const selectServices = (state: StoreState) => state.organisationSettings.services;
const selectCustomFormTemplates = (state: StoreState) => state.organisationSettings.customFormTemplates;
const selectCustomFormTemplate = (state: StoreState, customFormUid: string) =>
  state.organisationSettings.customFormTemplates?.data.find((c) => c.uid === customFormUid);
const selectPatientAlerts = (state: StoreState) => state.organisationSettings.patientAlerts;

export const OrganisationSettingsSlice = {
  reset,
  updatePatientFormSettings,
  updateLeadTypes,
  updateOrderFormSettings,
  updatePatientWorkflow,
  updateMeta,
  updateClinics,
  updateCalendarResources,
  updateAppointmentTypes,
  updateUploadTypes,
  updateAudiometers,
  updateHearingAids,
  updateAccessories,
  updateServices,
  updateCustomFormTemplates,
  updatePatientAlerts,
  selectState,
  selectPatientFormSettings,
  selectLeadTypes,
  selectOrderFormSettings,
  selectPatientWorkflow,
  selectPatientWorkflowStatuses,
  selectMeta,
  selectClinics,
  selectCalendarResources,
  selectAppointmentTypes,
  selectUploadTypes,
  selectClinic,
  selectAudiometers,
  selectHearingAids,
  selectAccessories,
  selectServices,
  selectCustomFormTemplates,
  selectCustomFormTemplate,
  selectPatientAlerts,
};

export default slice.reducer;
