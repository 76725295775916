import { App, Form } from 'antd';
import { CustomFormService } from 'core/api';
import { ControlType } from 'core/enums/control-type';
import { useDialog } from 'core/providers/dialog-provider';
import { useUserState } from 'core/providers/user-provider';
import { useTranslation } from 'react-i18next';
import SharedDialogBase from 'shared/dialog/dialog-base';
import SharedForm from 'shared/form/shared-form';
import { getActionTimestampFromUser } from 'shared/helpers/user-action.helpers';
import { v4 as uuidv4 } from 'uuid';
import { sentryCaptureException } from 'shared/helpers/sentry-helpers';
import { IPatientDao } from 'core/api/types';
import { useSelector } from 'react-redux';
import { OrganisationSettingsSlice } from 'modules/organisation-settings/organisation-settings-slice';
import { useWatch } from 'antd/es/form/Form';
import SharedCustomForm from 'shared/custom-form/custom-form';
import { StoreState } from 'store';
import { Timestamp } from 'firebase/firestore';
import clsx from 'clsx';
import { CustomFormTemplateComponentType } from 'core/constants/custom-form-component-type';

interface IAddPatientFormDialog {
  patient: IPatientDao;
}

const AddPatientFormDialog = ({ patient }: IAddPatientFormDialog) => {
  const { t } = useTranslation();
  const { userData } = useUserState();
  const dialog = useDialog();
  const { message } = App.useApp();
  const [selectCustomTemplateForm] = Form.useForm();

  const selectedCustomForm = useWatch('type', selectCustomTemplateForm);

  const customFormTemplates = useSelector(OrganisationSettingsSlice.selectCustomFormTemplates);
  const customFormTemplate = useSelector((state: StoreState) =>
    OrganisationSettingsSlice.selectCustomFormTemplate(state, selectedCustomForm)
  );

  const submit = async (data: Record<string, any>) => {
    try {
      if (!userData?.organisationUid) {
        throw new Error(t('auth.user.error'));
      }
      const userTimestamp = getActionTimestampFromUser(userData);
      const uid = uuidv4();
      const formValues = customFormTemplate?.components
        ?.filter((component) => component.type === CustomFormTemplateComponentType.FIELD)
        .reduce((acc: Record<string, any>, component) => {
          const value = data[component.key];
          if (!value) {
            return acc;
          }
          if (component.control === ControlType.DatePicker) {
            acc[component.key] = Timestamp.fromDate(value.toDate());
          } else if (component.control === ControlType.Signature) {
            acc[component.key] = value.toDataURL();
          } else {
            acc[component.key] = value;
          }
          return acc;
        }, {});
      await CustomFormService.set({
        uid,
        templateUid: selectedCustomForm,
        patientUid: patient.uid,
        organisationUid: userData.organisationUid,
        completed: true,
        created: userTimestamp,
        updated: userTimestamp,
        expires: Timestamp.fromDate(new Date()),
        formValues,
      });
      message.success(t('dialog.add_patient_form.success.description'));
      dialog?.closeDialog();
    } catch (error) {
      message.error(t('dialog.add_patient_form.error.description'));
      sentryCaptureException(error, 'Create Patient Form', userData);
    }
  };

  const customContent = () => {
    return (
      <div className='overflow-y-auto'>
        <SharedForm
          className={clsx('p-4', selectedCustomForm && 'border-b')}
          buttonsOverride={[]}
          formInstance={selectCustomTemplateForm}
          fields={[
            {
              fieldKey: 'type',
              control: ControlType.Select,
              label: 'Type',
              options:
                customFormTemplates?.data
                  ?.filter((form) => !form.deleted)
                  .map((form) => ({
                    value: form.uid,
                    label: form.name,
                  })) ?? [],
              required: true,
            },
          ]}
          name='select-custom-form-template'
        />
        {selectedCustomForm && (
          <SharedCustomForm<Record<string, any>>
            className='p-4 flex flex-col space-y-4'
            onFinish={submit}
            name='add-patient-custom-form'
            cancelButton={{
              onClick: () => dialog?.closeDialog(),
              appearance: 'text',
              labelKey: 'common.cancel',
            }}
            components={customFormTemplate?.components ?? []}
          />
        )}
      </div>
    );
  };

  return (
    <SharedDialogBase
      title={t('dialog.add_patient_form.title')}
      customContentTemplate={customContent()}
      showButtons={selectedCustomForm ? false : true}
    />
  );
};

export default AddPatientFormDialog;
