import { useDialog } from 'core/providers/dialog-provider';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import SharedButton from 'shared/button/button';
import AddEditComponentDialog from './add-edit-custom-form-template-add-edit-component-dialog';
import FormItemMimic from 'shared/form/form-item-mimic';
import { ControlTypeData } from 'core/enums/control-type';
import { ICustomFormTemplateComponent } from 'core/api/types/custom-form-templates.interface';
import {
  CustomFormTemplateComponentType,
  CustomFormTemplateComponentTypeData,
} from 'core/constants/custom-form-component-type';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { HolderOutlined } from '@ant-design/icons';

interface IAddEditCustomFormComponent {
  component: ICustomFormTemplateComponent;
  setComponents: Dispatch<SetStateAction<ICustomFormTemplateComponent[]>>;
}

const AddEditCustomFormComponent = ({ component, setComponents }: IAddEditCustomFormComponent) => {
  const { t } = useTranslation();
  const dialog = useDialog();
  const typeData = CustomFormTemplateComponentTypeData[component.type];
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: component.key,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const getComponentTemplate = () => {
    if (!typeData) {
      return <></>;
    }

    if (component.type === CustomFormTemplateComponentType.FIELD) {
      const fields = [
        {
          key: 'label',
          label: t('form_settings.custom_forms.add_edit_form.components.add_edit_component.label'),
          value: component.label,
        },
        {
          key: 'controlType',
          label: t('form_settings.custom_forms.add_edit_form.components.add_edit_component.control_type'),
          value: t(ControlTypeData[component.control].translationLabelKey ?? 'common.unknown'),
        },
        {
          key: 'required',
          label: t('form_settings.custom_forms.add_edit_form.components.add_edit_component.required'),
          value: component.required ? t('common.yes') : t('common.no'),
        },
        ...(component.options
          ? [
              {
                key: 'options',
                label: t('form_settings.custom_forms.add_edit_form.components.add_edit_component.options'),
                value: component.options.join(', '),
              },
            ]
          : []),
      ];
      return (
        <div>
          {fields.map((field) => (
            <FormItemMimic
              className='mb-4 last:mb-0'
              key={field.key}
              label={field.label}
              labelClassName='text-xs font-semibold'
            >
              <p>{field.value}</p>
            </FormItemMimic>
          ))}
        </div>
      );
    }

    return (
      <FormItemMimic
        label={t('form_settings.custom_forms.add_edit_form.components.add_edit_component.content')}
        className='mb-0'
        labelClassName='text-xs font-semibold'
      >
        <div
          className='max-h-[120px] overflow-y-auto rich-text'
          dangerouslySetInnerHTML={{ __html: component.content }}
        />
      </FormItemMimic>
    );
  };

  return (
    <div
      ref={setNodeRef}
      {...attributes}
      {...listeners}
      style={style}
      className='border-t first:border-0 flex items-stretch'
    >
      <div className='grow px-1.5 border-r flex items-center justify-center'>
        <HolderOutlined />
      </div>
      <div className='w-full p-4'>
        <div className='flex items-center justify-between'>
          <p className='body-sm text-gray-400'>{t(typeData.translationLabelKey)}</p>
          <div className='space-x-2'>
            <SharedButton
              appearance='link'
              labelKey='common.edit'
              primaryOverride
              onClick={() =>
                dialog?.openDialog(<AddEditComponentDialog component={component} setComponents={setComponents} />)
              }
            />
            <SharedButton
              appearance='link'
              labelKey='common.delete'
              danger
              onClick={() => setComponents((prev) => prev.filter((c) => c.key !== component.key))}
            />
          </div>
        </div>
        <div>{getComponentTemplate()}</div>
      </div>
    </div>
  );
};

export default AddEditCustomFormComponent;
