export enum CommunicationType {
  APPOINTMENT_REMINDER = 'appointmentReminder',
  APPOINTMENT_CONFIRMATION = 'appointmentConfirmation',
  ORDER_CONFIRMATION = 'orderConfirmation',
  ADHOC = 'adhoc',
  FITTING_RECEIPT = 'fittingReceipt',
}

export const CommunicationTypeData = {
  [CommunicationType.APPOINTMENT_REMINDER]: {
    translationLabelKey: 'communication_type.appointment_reminder',
    value: CommunicationType.APPOINTMENT_REMINDER,
  },
  [CommunicationType.APPOINTMENT_CONFIRMATION]: {
    translationLabelKey: 'communication_type.appointment_confirmation',
    value: CommunicationType.APPOINTMENT_CONFIRMATION,
  },
  [CommunicationType.ADHOC]: {
    translationLabelKey: 'communication_type.adhoc',
    value: CommunicationType.ADHOC,
  },
  [CommunicationType.ORDER_CONFIRMATION]: {
    translationLabelKey: 'communication_type.order_confirmation',
    value: CommunicationType.ORDER_CONFIRMATION,
  },
  [CommunicationType.FITTING_RECEIPT]: {
    translationLabelKey: 'communication_type.fitting_receipt',
    value: CommunicationType.FITTING_RECEIPT,
  },
};
