import { useDialog } from 'core/providers/dialog-provider';
import { useState } from 'react';
import SharedDialogBase from './dialog-base';
import { useUserState } from 'core/providers/user-provider';
import { ControlType } from 'core/enums/control-type';
import { useTranslation } from 'react-i18next';
import SharedForm from 'shared/form/shared-form';
import { ISharedField } from 'shared/fields/shared-fields.interface';
import { useSelector } from 'react-redux';
import {
  IDomainCalendarResource,
  OrganisationSettingsSlice,
} from 'modules/organisation-settings/organisation-settings-slice';
import dayjs from 'dayjs';
import { UsersApiService } from 'core/api';
import { getActionTimestampFromUser } from 'shared/helpers/user-action.helpers';
import { App } from 'antd';

interface ISetWorkingLocationDialog {
  date: dayjs.Dayjs;
  user: IDomainCalendarResource;
}

interface ISetWorkingLocationFormOutput {
  location: string;
}

const SetWorkingLocationDialog = ({ date, user }: ISetWorkingLocationDialog) => {
  const [loading, setLoading] = useState(false);
  const dialog = useDialog();
  const { userData } = useUserState();
  const { t } = useTranslation();
  const { message } = App.useApp();
  const clinicsState = useSelector(OrganisationSettingsSlice.selectClinics);
  const selectOptions = [
    ...(clinicsState?.data.map((clinic) => ({ label: clinic.name, value: clinic.uid })) ?? []),
    {
      label: t('working_location.domiciliary'),
      value: 'domiciliary',
    },
    {
      label: t('working_location.non_working'),
      value: 'nonWorking',
    },
  ];
  const fields: ISharedField[] = [
    {
      fieldKey: 'location',
      control: ControlType.Select,
      options: selectOptions,
      label: t('dialog.set_working_location.form.location'),
      required: false,
      allowClear: true,
    },
  ];

  const onFormSubmit = async (data: ISetWorkingLocationFormOutput) => {
    try {
      const workingLocationOverrides = {
        ...user.workingLocationOverrides,
      };
      if (data.location) {
        workingLocationOverrides[date.format('YYYY-MM-DD')] = data.location;
      } else {
        delete workingLocationOverrides[date.format('YYYY-MM-DD')];
      }
      setLoading(true);
      await UsersApiService.update(user.uid, {
        workingLocationOverrides,
        updated: getActionTimestampFromUser(userData),
      });
      message.success(t('dialog.set_working_location.update.success'));
      dialog?.closeDialog();
    } catch (error) {
      setLoading(false);
      message.error(t('dialog.set_working_location.update.error'));
    }
  };

  const customContent = () => {
    return (
      <SharedForm<ISetWorkingLocationFormOutput>
        fields={fields}
        onFinish={onFormSubmit}
        submitting={loading}
        name='set-working-location-form'
        cancelButton={{ labelKey: 'common.cancel', appearance: 'text', onClick: () => dialog?.closeDialog() }}
        existingValue={{
          location: user.workingLocationOverrides?.[date.format('YYYY-MM-DD')] ?? user.workingLocations?.[date.day()],
        }}
      />
    );
  };

  return (
    <SharedDialogBase
      title={t('dialog.set_working_location.title', {
        date: date.format('DD/MM/YYYY'),
      })}
      customContentTemplate={customContent()}
      showButtons={false}
    />
  );
};

export default SetWorkingLocationDialog;
