import clsx from 'clsx';
import { IAppointmentDao } from 'core/api/types/appointment.interface';
import { AppointmentLocation, AppointmentLocationData } from 'core/constants/appointment-location';
import { useTheme } from 'core/providers/theme-provider';
import { useUserState } from 'core/providers/user-provider';
import dayjs from 'dayjs';
import { OrganisationSettingsSlice } from 'modules/organisation-settings/organisation-settings-slice';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getAppointmentStyle, getTimeStringFromTimestamp } from 'shared/helpers/appointment-helpers';
import { StoreState } from 'store';
import AppointmentMenu from 'shared/appointment/appointment-menu';
import { CalendarMode } from 'core/constants/calendar-mode';
import { useEffect, useState } from 'react';
import { Popover } from 'antd';
import { useResponsiveState } from 'core/providers/responsive-provider';
import { Phone } from 'react-feather';

interface ISharedCalendarAppointment {
  appointment: IAppointmentDao;
  timeSlotHeight: number;
  widthPercent: number;
  leftPercent: number;
  showAppointmentMenu: boolean;
  mode: CalendarMode;
}

const SharedCalendarAppointment = ({
  appointment,
  timeSlotHeight,
  widthPercent,
  leftPercent,
  showAppointmentMenu,
  mode,
}: ISharedCalendarAppointment) => {
  const { primary } = useTheme();
  const { t } = useTranslation();
  const appointmentTypeState = useSelector(OrganisationSettingsSlice.selectAppointmentTypes);
  const appointmentTypeDetail = appointmentTypeState?.data.find((type) => type.uid === appointment.type);
  const outcome = appointmentTypeDetail?.outcomes.find((outcome) => outcome.key === appointment.outcome);
  const startTimeString = getTimeStringFromTimestamp(appointment.startDateTime);
  const endTimeString = getTimeStringFromTimestamp(appointment.endDateTime);
  const clinic = useSelector((state: StoreState) => OrganisationSettingsSlice.selectClinic(state, appointment.clinic));
  const location = AppointmentLocationData[appointment.location];
  const { organisationData } = useUserState();
  const start = dayjs(organisationData?.calendar.startTime.toDate());
  const { isMobile } = useResponsiveState();
  const [popoverTrigger, setPopoverTrigger] = useState<'hover' | 'focus' | 'click' | 'contextMenu'>('hover');
  const style = getAppointmentStyle(
    start,
    dayjs(appointment.startDateTime.toDate()),
    dayjs(appointment.endDateTime.toDate()),
    appointment.cancelled,
    widthPercent,
    leftPercent,
    timeSlotHeight,
    appointmentTypeDetail?.colour ?? primary.bg
  );
  const height = parseInt(style.height);
  const isTiny = height < 25;

  useEffect(() => {
    if (isMobile) {
      setPopoverTrigger('click');
    }
  }, [isMobile]);

  const getAddress = () => {
    let address = appointment.patient.address?.formattedAddress;
    if (appointment.location === AppointmentLocation.CLINIC) {
      address = undefined;
    }
    return address ? <p className='body-xs'>{address}</p> : <></>;
  };

  const getTypeAndLocation = () => {
    const values = [appointmentTypeDetail?.name, location ? t(location?.translationLabelKey) : undefined].filter(
      Boolean
    );
    return values.length === 0 ? <></> : <p className='body-xs opacity-70'>{values.join(' - ')}</p>;
  };

  const getClinicAndPerson = () => {
    const values = [clinic?.name, mode === CalendarMode.CLINICS ? appointment.assignee.fullName : undefined].filter(
      Boolean
    );
    return values.length === 0 ? <></> : <p className='body-xs opacity-70'>{values.join(' - ')}</p>;
  };

  const appointmentContent = (contentClassName?: string, popover?: boolean) => {
    const {
      calendar: { appointmentTimeNextToPxName, hideEndTime },
    } = organisationData!;
    const times = [startTimeString];
    if (!hideEndTime) {
      times.push(endTimeString);
    }

    const phoneNumbers = [appointment.patient.phoneNumber, appointment.patient.secondaryPhoneNumber];

    return (
      <>
        {outcome && (
          <span
            className='flex w-full px-2 py-1.5 font-semibold body-xs text-white'
            style={{ backgroundColor: outcome.bannerColour }}
          >
            {outcome.bannerLabel}
          </span>
        )}
        <div className={contentClassName}>
          <div className='flex'>
            <div
              className={clsx(
                'flex',
                popover || !appointmentTimeNextToPxName ? 'flex-col' : 'flex-row-reverse items-center'
              )}
            >
              <p
                className={clsx(
                  'font-semibold',
                  isTiny && 'body-xs',
                  popover || !appointmentTimeNextToPxName ? '' : 'ml-1.5'
                )}
              >
                {appointment.patient.fullName}
              </p>
              <p className='body-xs'>{times.join(' - ')}</p>
            </div>
          </div>
          {getAddress()}
          {popover && (
            <div className='flex flex-col space-y-1.5 my-2'>
              {phoneNumbers.filter(Boolean).map((phoneNumber) => (
                <a key={phoneNumber} className='flex items-center' href={`tel:${phoneNumber}`} style={{ color: primary.bg }}>
                  <Phone size={12} />
                  <p className='body-xs ml-1'>{phoneNumber}</p>
                </a>
              ))}
            </div>
          )}
          {getTypeAndLocation()}
          {getClinicAndPerson()}
          {popover && appointment.additionalNote && (
            <div className='mt-3 body-xs'>
              <p className='text-gray-500'>{t('calendar.add_edit_appointment.form.additional_information')}</p>
              <p>{appointment.additionalNote}</p>
            </div>
          )}
        </div>
      </>
    );
  };

  return (
    <div
      className={clsx(
        'group absolute w-full rounded-md shadow-sm text-white z-20 overflow-y-auto hide-scrollbar bg-red-500 flex justify-between items-start',
        appointment.cancelled && 'line-through'
      )}
      style={style}
    >
      <div className='relative w-full h-full'>
        {showAppointmentMenu && (
          <AppointmentMenu
            appointment={appointment}
            className='absolute top-0 right-0 md:hidden md:group-hover:block z-10'
          />
        )}
        <Popover content={appointmentContent('max-w-[350px] mt-1', true)} trigger={popoverTrigger} className='h-full'>
          <div>{appointmentContent(isTiny ? 'p-1' : 'p-2')}</div>
        </Popover>
      </div>
    </div>
  );
};

export default SharedCalendarAppointment;
