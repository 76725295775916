import { App } from 'antd';
import { IUserDao } from 'core/api/types';
import { UsersApiService } from 'core/api';
import { useDialog } from 'core/providers/dialog-provider';
import { useAComponentPortal } from 'core/providers/component-portal-provider';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import SharedDialogBase, { ISharedDialogButton } from 'shared/dialog/dialog-base';

interface IAdminDeleteUserDialog {
  user: IUserDao;
  tableKey: string;
}

const AdminDeleteUserDialog = ({ user, tableKey }: IAdminDeleteUserDialog) => {
  const [loading, setLoading] = useState(false);
  const dialog = useDialog();
  const { message } = App.useApp();
  const { t } = useTranslation();
  const table = useAComponentPortal(tableKey);

  const deleteUser = async () => {
    setLoading(true);
    try {
      await UsersApiService.adminRemove(user);
      dialog?.closeDialog();
      message.success(t('dialog.delete_user.success.description'));
      table?.exposedFunction();
    } catch (error) {
      message.error(t('dialog.delete_user.error.description'));
      setLoading(false);
    }
  };

  const customButtons: ISharedDialogButton[] = [
    {
      key: 'cancel',
      props: {
        onClick: () => dialog?.closeDialog(),
        appearance: 'text',
        labelKey: 'common.cancel',
      },
    },
    {
      key: 'deleteUser',
      props: {
        loading,
        onClick: () => deleteUser(),
        danger: true,
        labelKey: 'common.delete',
      },
    },
  ];

  return (
    <SharedDialogBase
      title={t('dialog.delete_user.title', { userName: user.fullName })}
      textContent={t('dialog.delete_user.content')}
      customButtons={customButtons}
    />
  );
};

export default AdminDeleteUserDialog;
