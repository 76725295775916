import { App } from 'antd';
import { ControlType } from 'core/enums/control-type';
import { useDialog } from 'core/providers/dialog-provider';
import { useUserState } from 'core/providers/user-provider';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import SharedDialogBase from 'shared/dialog/dialog-base';
import { ISharedField } from 'shared/fields/shared-fields.interface';
import SharedForm from 'shared/form/shared-form';
import { sentryCaptureException } from 'shared/helpers/sentry-helpers';
import { IPatientDao, IPatientDocumentDao } from 'core/api/types';
import { InputType } from 'core/enums/input-type';
import SharedButton from 'shared/button/button';
import EmailDocumentTemplate from 'shared/email-previews/email-document';
import { PatientDocumentsApiService } from 'core/api';

interface IEmailPatientDocumentDialog {
  patient: IPatientDao;
  document: IPatientDocumentDao;
  uploadType?: string;
}

interface IAddPatientDocumentFormOutput {
  emailAddress: string;
  subject: string;
  heading: string;
  body: string;
}

const EmailPatientDocumentDialog = ({ patient, document, uploadType }: IEmailPatientDocumentDialog) => {
  const { t } = useTranslation();
  const { userData, organisationData } = useUserState();
  const dialog = useDialog();
  const { message } = App.useApp();
  const [submitting, setSubmitting] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const defaultBody = `Dear ${patient.fullName},\n\nPlease find your ${uploadType} document attached to this email.\n\nKind regards,\n${organisationData?.name}`;
  const [emailBody, setEmailBody] = useState<string | undefined>(defaultBody);
  const defaultHeading = `Your document from ${organisationData?.name}`;
  const [heading, setHeading] = useState<string | undefined>(defaultHeading);

  const fields: ISharedField[] = [
    {
      fieldKey: 'emailAddress',
      control: ControlType.TextField,
      type: InputType.Email,
      label: t('patients.patient.patient_documents.email.form.email'),
      required: true,
      customRules: [
        {
          type: 'email',
        },
      ],
    },
    {
      fieldKey: 'subject',
      control: ControlType.TextField,
      type: InputType.Text,
      label: t('patients.patient.patient_documents.email.form.subject'),
      required: true,
    },
    {
      fieldKey: 'heading',
      control: ControlType.TextField,
      type: InputType.Text,
      label: t('patients.patient.patient_documents.email.form.heading'),
      required: true,
    },
    {
      fieldKey: 'body',
      control: ControlType.TextArea,
      rows: 6,
      label: t('patients.patient.patient_documents.email.form.email_body'),
      required: true,
      extra: (
        <SharedButton
          onClick={() => setShowPreview((prev) => !prev)}
          labelKey={
            showPreview
              ? 'patients.patient.patient_documents.email.form.hide_email_preview'
              : 'patients.patient.patient_documents.email.form.show_email_preview'
          }
        />
      ),
    },
  ];

  const submit = async (data: IAddPatientDocumentFormOutput) => {
    setSubmitting(true);
    try {
      if (!userData?.organisationUid) {
        throw new Error(t('auth.user.error'));
      }
      await PatientDocumentsApiService.emailDocument({ documentUid: document.uid, ...data });
      message.success(t('patients.patient.patient_documents.email.success'));
      dialog?.closeDialog();
    } catch (error) {
      message.error(t('patients.patient.patient_documents.email.error'));
      setSubmitting(false);
      sentryCaptureException(error, 'Create Patient Document', userData);
    }
  };

  const customContent = () => {
    return (
      <div>
        <SharedForm<IAddPatientDocumentFormOutput>
          onFinish={submit}
          fields={fields}
          submitting={submitting}
          cancelButton={{
            onClick: () => dialog?.closeDialog(),
            appearance: 'text',
            labelKey: 'common.cancel',
          }}
          name='email-patient-document'
          existingValue={{
            emailAddress: patient.emailAddress,
            subject: uploadType,
            body: defaultBody,
            heading: defaultHeading,
          }}
          onChange={(change) => {
            const [key, value] = Object.entries(change)[0];
            if (key === 'body') {
              setEmailBody(value as string | undefined);
            } else if (key === 'heading') {
              setHeading(value as string | undefined);
            }
          }}
        />
        {showPreview && <EmailDocumentTemplate emailBody={emailBody} heading={heading} />}
      </div>
    );
  };

  return (
    <SharedDialogBase
      title={t('patients.patient.patient_documents.email.title')}
      customContentTemplate={customContent()}
      showButtons={false}
    />
  );
};

export default EmailPatientDocumentDialog;
