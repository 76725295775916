import { Alert, App, Button, Form } from 'antd';
import { BookingWidgetApiService } from 'core/api';
import { IBookingWidgetAppointmentSlot, IBookingWidgetConfigResponse } from 'core/api/types/booking-widget.interface';
import { TitleData, TitleOptions } from 'core/constants/title';
import { ControlType } from 'core/enums/control-type';
import { InputType } from 'core/enums/input-type';
import { useTheme } from 'core/providers/theme-provider';
import dayjs, { Dayjs } from 'dayjs';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Clock } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import SharedButton from 'shared/button/button';
import SharedCard from 'shared/card/card';
import { ISharedField } from 'shared/fields/shared-fields.interface';
import SharedForm from 'shared/form/shared-form';
import { sentryCaptureException } from 'shared/helpers/sentry-helpers';
import SharedSpinner from 'shared/spinner/spinner';
import { CheckCircleTwoTone } from '@ant-design/icons';

interface IBookingWidgetFormOutput {
  patientTitle: string;
  patientName: string;
  patientPhoneNumber: string;
  appointmentType: string;
  clinic: string;
  consultant: string;
  appointmentDate: Dayjs;
}

const BookingWidget = () => {
  const [searchParams] = useSearchParams();
  const id = searchParams.get('id');
  const [loadingConfig, setLoadingConfig] = useState(true);
  const [config, setConfig] = useState<IBookingWidgetConfigResponse>();
  const { message } = App.useApp();
  const { t } = useTranslation();
  const { primary } = useTheme();
  const [form] = Form.useForm();
  const [formValues, setFormValues] = useState<Partial<IBookingWidgetFormOutput>>({
    appointmentDate: config?.enabledDays.includes(dayjs().day()) ? dayjs() : undefined,
  });
  const [formComplete, setFormComplete] = useState(false);
  const [loadingAppointmentSlots, setLoadingAppointmentSlots] = useState(false);
  const [availableSlots, setAvailableSlots] = useState<IBookingWidgetAppointmentSlot[]>([]);
  const [selectedSlot, setSelectedSlot] = useState<IBookingWidgetAppointmentSlot>();
  const [confirmingBooking, setConfirmingBooking] = useState(false);
  const [bookingConfirmed, setBookingConfirmed] = useState(false);

  const matchingConsultants = useMemo(
    () =>
      config?.consultants
        .filter(
          ({ clinics, appointmentTypes }) =>
            clinics.includes(formValues?.clinic!) && appointmentTypes.includes(formValues?.appointmentType!)
        )
        .map((specialist) => ({ label: specialist.fullName, value: specialist.uid })) ?? [],
    [config?.consultants, formValues?.appointmentType, formValues?.clinic]
  );

  const getBookingWidgetConfig = useCallback(
    async (id: string) => {
      try {
        const config = await BookingWidgetApiService.getBookingWidgetConfig(id);
        setConfig(config);
        setLoadingConfig(false);
      } catch (error) {
        message.error(t('booking_widget.get_config.error'));
      }
    },
    [message, t]
  );

  useEffect(() => {
    if (id) {
      getBookingWidgetConfig(id);
    }
  }, [getBookingWidgetConfig, id]);

  const getAvailableAppointmentSlots = useCallback(
    async (id: string, type: string, date: Dayjs, consultant?: string) => {
      try {
        setLoadingAppointmentSlots(true);
        const slots = await BookingWidgetApiService.getAvailableAppointmentSlots({
          id,
          appointmentType: type,
          specifiedConsultant: consultant,
          date: date.format('DD/MM/YYYY'),
          matchingConsultants: matchingConsultants.map((consultant) => consultant.value),
        });
        setAvailableSlots(Object.values(slots));
        setLoadingAppointmentSlots(false);
      } catch (error) {
        message.error(t('booking_widget.get_time_slots.error'));
      }
    },
    [matchingConsultants, message, t]
  );

  useEffect(() => {
    if (id && formValues.appointmentType && formValues.clinic && formValues.appointmentDate) {
      setFormComplete(true);
      getAvailableAppointmentSlots(id, formValues.appointmentType, formValues.appointmentDate, formValues.consultant);
    }
  }, [
    formValues.appointmentType,
    formValues.clinic,
    formValues.appointmentDate,
    getAvailableAppointmentSlots,
    id,
    formValues.consultant,
  ]);

  useEffect(() => {
    setSelectedSlot(undefined);
  }, [formValues.appointmentType, formValues.consultant, formValues.appointmentDate, formValues.clinic]);

  const detailsFields: ISharedField[] = [
    {
      fieldKey: 'patientTitle',
      control: ControlType.Select,
      options: TitleOptions.map((op) => {
        const option = TitleData[op];
        return {
          label: t(option.translationLabelKey),
          value: option.value,
        };
      }),
      required: false,
      label: t('booking_widget.form.title'),
    },
    {
      fieldKey: 'patientName',
      control: ControlType.TextField,
      type: InputType.Text,
      label: t('booking_widget.form.patient_name'),
      required: true,
    },
    {
      fieldKey: 'patientPhoneNumber',
      control: ControlType.TextField,
      type: InputType.Text,
      label: t('booking_widget.form.patient_phone_number'),
      required: true,
    },
    {
      fieldKey: 'appointmentType',
      control: ControlType.Select,
      label: t('booking_widget.form.appointment_type'),
      required: true,
      options: config?.appointmentTypes ?? [],
    },
    {
      fieldKey: 'clinic',
      control: ControlType.Select,
      label: t('booking_widget.form.clinic'),
      required: true,
      options: config?.clinics ?? [],
    },
    {
      fieldKey: 'appointmentDate',
      control: ControlType.DatePicker,
      label: t('booking_widget.form.appointment_date'),
      minDate: dayjs(),
      required: true,
      disabledDate: (current: Dayjs) => !config?.enabledDays.includes(current.day()),
      fullWidth: true,
    },
  ];

  const appointmentFields: ISharedField[] = [
    {
      fieldKey: 'consultant',
      control: ControlType.Select,
      label: t('booking_widget.form.consultant'),
      required: false,
      allowClear: true,
      options: matchingConsultants,
    },
  ];

  const getAppointmentTypeDuration = (uid?: string) =>
    config?.appointmentTypes.find((type) => type.value === uid)?.duration;

  const confirmBooking = async () => {
    try {
      setConfirmingBooking(true);
      await form.validateFields();
      if (!selectedSlot) {
        throw new Error('No slot selected');
      }

      try {
        await BookingWidgetApiService.confirmBooking({
          id: id!,
          date: formValues.appointmentDate!.format('DD/MM/YYYY'),
          slot: selectedSlot!.slot,
          duration: getAppointmentTypeDuration(formValues.appointmentType)!,
          clinic: formValues.clinic!,
          consultant: formValues.consultant ?? selectedSlot.availableConsultants[0],
          title: formValues.patientTitle,
          fullName: formValues.patientName!,
          phoneNumber: formValues.patientPhoneNumber!,
          appointmentType: formValues.appointmentType!,
        });
        setConfirmingBooking(false);
        setBookingConfirmed(true);
      } catch (error) {
        message.error(t('booking_widget.confirm_booking.error'));
        sentryCaptureException(error, 'Confirming booking in booking widget');
        setConfirmingBooking(false);
      }
    } catch (error) {
      setConfirmingBooking(false);
    }
  };

  return (
    <div className='flex flex-col items-center justify-center px-4 pt-4 min-h-[693px]'>
      {loadingConfig || !config ? (
        <div className='grow flex justify-center items-center'>
          <SharedSpinner color={primary.bg} />
        </div>
      ) : (
        <>
          <SharedCard outerClassName='w-full min-w-[600px]' innerClassName='grid grid-cols-2'>
            {bookingConfirmed ? (
              <div className='col-span-2 p-16 flex flex-col items-center'>
                <img
                  src={config.organisationBranding.logoUrl}
                  alt='organisation-logo'
                  className='max-h-[70px] mx-auto mb-8'
                />

                <CheckCircleTwoTone twoToneColor='#16a34a' className='text-2xl mb-4' />
                <p className='header-lg mb-2'>{t('booking_widget.confirmed.title')}</p>
                <p className='body-sm text-center max-w-[600px]'>
                  {t('booking_widget.confirmed.description', {
                    organisationName: config.organisationBranding.name,
                    date: formValues.appointmentDate!.format('DD/MM/YYYY'),
                    time: selectedSlot?.slot,
                    clinic: config.clinics.find((clinic) => clinic.value === formValues.clinic)?.label,
                  })}
                </p>
              </div>
            ) : (
              <>
                <div className='border-r'>
                  <div className='p-8 border-b'>
                    <img
                      src={config.organisationBranding.logoUrl}
                      alt='organisation-logo'
                      className='max-h-[70px] mx-auto'
                    />
                  </div>
                  <div className='p-8'>
                    <p className='header-lg mb-4'>{t('booking_widget.title')}</p>
                    <p className='mb-4'>
                      {t('booking_widget.intro', { organisationName: config.organisationBranding.name })}
                    </p>
                    <SharedForm<IBookingWidgetFormOutput>
                      onChange={(newValue) => setFormValues((prev) => ({ ...prev, ...newValue }))}
                      formInstance={form}
                      name='booking-widget-details-form'
                      fields={detailsFields}
                      className=''
                      buttonsOverride={[]}
                    />
                  </div>
                </div>
                <div className='flex flex-col overflow-y-auto'>
                  {formComplete ? (
                    <>
                      <div className='px-8 pt-8'>
                        <p className='header-lg mb-4'>{t('booking_widget.calendar.title')}</p>
                        <div className='flex text-gray-500 items-center space-x-2 body-sm mb-4'>
                          <Clock size={20} />
                          <p>
                            {t('booking_widget.calendar.appointment_duration', {
                              duration: getAppointmentTypeDuration(formValues?.appointmentType),
                            })}
                          </p>
                        </div>
                        <SharedForm<IBookingWidgetFormOutput>
                          onChange={(newValue) => setFormValues((prev) => ({ ...prev, ...newValue }))}
                          formInstance={form}
                          name='booking-widget-appointments-form'
                          fields={appointmentFields}
                          className=''
                          buttonsOverride={[]}
                          existingValue={{
                            appointmentDate: dayjs(),
                          }}
                        />
                      </div>
                      {loadingAppointmentSlots || !availableSlots ? (
                        <div className='grow flex items-center justify-center'>
                          <SharedSpinner color={primary.bg} />
                        </div>
                      ) : (
                        <div className='grid grid-cols-3 gap-4 p-8 pt-4'>
                          {availableSlots
                            .sort((a, b) => {
                              const aDate = dayjs(a.slot, 'HH:mm');
                              const bDate = dayjs(b.slot, 'HH:mm');
                              return aDate.isBefore(bDate) ? -1 : aDate.isAfter(bDate) ? 1 : 0;
                            })
                            .map(({ slot, availableConsultants }) => (
                              <Button
                                key={slot}
                                onClick={() =>
                                  setSelectedSlot({
                                    slot,
                                    availableConsultants,
                                  })
                                }
                                type={selectedSlot?.slot === slot ? 'primary' : 'default'}
                              >
                                {slot}
                              </Button>
                            ))}
                          {availableSlots.length === 0 && (
                            <Alert className='col-span-4' message={t('booking_widget.calendar.no_slots')} type='info' />
                          )}
                        </div>
                      )}
                    </>
                  ) : (
                    <div className='grow flex items-center justify-center'>
                      <p className='text-gray-400 body-sm text-center max-w-[300px]'>
                        {t('booking_widget.calendar.empty')}
                      </p>
                    </div>
                  )}
                </div>
                {selectedSlot && (
                  <div className='border-t p-8 py-6 col-span-2 flex justify-end'>
                    <SharedButton
                      labelKey='common.confirm'
                      appearance='primary'
                      onClick={confirmBooking}
                      loading={confirmingBooking}
                    />
                  </div>
                )}
              </>
            )}
          </SharedCard>
        </>
      )}
    </div>
  );
};

export default BookingWidget;
