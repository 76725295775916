import { App } from 'antd';
import { PatientAlertsApiService } from 'core/api';
import { IPatientAlertDao } from 'core/api/types/patient-alert.interface';
import { Permission } from 'core/constants/permission';
import { useAComponentPortal } from 'core/providers/component-portal-provider';
import { useUserState } from 'core/providers/user-provider';
import { IDomainPatientAlert } from 'modules/organisation-settings/organisation-settings-slice';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import SharedButton from 'shared/button/button';
import { sentryCaptureException } from 'shared/helpers/sentry-helpers';
import { getActionTimestampFromUser } from 'shared/helpers/user-action.helpers';
import SharedElementPermissionGuard from 'shared/permissions/element-permission-guard';

interface IPatientAlertAcknowledgementButton {
  alert: IPatientAlertDao | IDomainPatientAlert;
  tableKey?: string;
}

const PatientAlertAcknowledgementButton = ({ alert, tableKey }: IPatientAlertAcknowledgementButton) => {
  const [loading, setLoading] = useState(false);
  const { userData } = useUserState();
  const { message } = App.useApp();
  const { t } = useTranslation();
  const table = useAComponentPortal(tableKey ?? '');

  const handleButtonClick = async () => {
    setLoading(true);
    try {
      await PatientAlertsApiService.update(alert.uid, {
        acknowledged: !alert.acknowledged,
        updated: getActionTimestampFromUser(userData),
      });
      message.success(t('patients.patient.alerts.acknowledgement.success'));
      table?.exposedFunction();
    } catch (error) {
      message.error(t('patients.patient.alerts.acknowledgement.error'));
      sentryCaptureException(error, 'Patient alert acknowledgement', userData);
    }
    setLoading(false);
  };

  return (
    <SharedElementPermissionGuard
      requiredPermissions={[[Permission.PATIENT_ALERTS_UPDATE], [Permission.ORGANISATION_OWNER]]}
    >
      <SharedButton
        loading={loading}
        onClick={handleButtonClick}
        type='button'
        appearance='link'
        labelKey={alert.acknowledged ? 'common.unacknowledge' : 'common.acknowledge'}
        primaryOverride
      />
    </SharedElementPermissionGuard>
  );
};

export default PatientAlertAcknowledgementButton;
