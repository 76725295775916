import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import SharedDialogBase from 'shared/dialog/dialog-base';
import { Select } from 'antd';
import FormItemMimic from 'shared/form/form-item-mimic';
import AddEditStockHearingAidForm from './add-edit-stock-hearing-aid-form';
import { IStockDao } from 'core/api/types/stock.interface';
import AddEditStockAccessoryForm from './add-edit-stock-accessory-form';
interface IAddEditStockDialog {
  tableKey?: string;
  selectedType?: string;
  stock?: IStockDao;
}

const AddEditStockDialog = ({ tableKey = '', selectedType, stock }: IAddEditStockDialog) => {
  const { t } = useTranslation();
  const [type, setType] = useState<string | undefined>(selectedType);

  const customContent = () => {
    return (
      <div className='p-4 overflow-y-auto'>
        <FormItemMimic label={t('stock_management.add_edit_stock.form.type')}>
          <Select
            value={type}
            className='w-full'
            options={[
              { label: t('stock_management.add_edit_stock.form.type.hearing_aid'), value: 'hearingAid' },
              { label: t('stock_management.add_edit_stock.form.type.accessory'), value: 'accessory' },
            ]}
            onChange={(value) => setType(value)}
          />
        </FormItemMimic>
        {type === 'hearingAid' && (
          <AddEditStockHearingAidForm tableKey={tableKey} stock={stock?.type === 'hearingAid' ? stock : undefined} />
        )}
        {type === 'accessory' && (
          <AddEditStockAccessoryForm tableKey={tableKey} stock={stock?.type === 'accessory' ? stock : undefined} />
        )}
      </div>
    );
  };

  return (
    <SharedDialogBase
      title={t(stock ? 'stock_management.add_edit_stock.update.title' : 'stock_management.add_edit_stock.create.title')}
      customContentTemplate={customContent()}
      showButtons={!type}
    />
  );
};

export default AddEditStockDialog;
