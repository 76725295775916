import { App } from 'antd';
import { IClinicDao } from 'core/api/types';
import { ClinicApiService } from 'core/api';
import { ControlType } from 'core/enums/control-type';
import { InputType } from 'core/enums/input-type';
import { useDialog } from 'core/providers/dialog-provider';
import { useAComponentPortal } from 'core/providers/component-portal-provider';
import { useUserState } from 'core/providers/user-provider';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import SharedDialogBase from 'shared/dialog/dialog-base';
import { ISharedField } from 'shared/fields/shared-fields.interface';
import SharedForm from 'shared/form/shared-form';
import { getActionTimestampFromUser } from 'shared/helpers/user-action.helpers';
import { IAddressInputOutput } from 'shared/interfaces/address.interface';
import { v4 as uuidv4 } from 'uuid';
import { sentryCaptureException } from 'shared/helpers/sentry-helpers';

const nameFieldKey = 'name';
const addressFieldKey = 'address';
const emailFieldKey = 'emailAddress';
const phoneFieldKey = 'phoneNumber';

interface IAddEditClinicFormOutput {
  [nameFieldKey]: string;
  [addressFieldKey]: IAddressInputOutput;
  [emailFieldKey]: string;
  [phoneFieldKey]: string;
}

interface IAddEditClinicDialog {
  clinic?: IClinicDao;
  tableKey: string;
}

const AddEditClinicDialog = ({ clinic, tableKey }: IAddEditClinicDialog) => {
  const { t } = useTranslation();
  const { userData } = useUserState();
  const dialog = useDialog();
  const [submitting, setSubmitting] = useState(false);
  const { message } = App.useApp();
  const AddEditClinicDialogFields: ISharedField[] = [
    {
      fieldKey: nameFieldKey,
      control: ControlType.TextField,
      type: InputType.Text,
      label: t('common.name'),
      required: true,
    },
    {
      fieldKey: addressFieldKey,
      control: ControlType.Address,
      label: t('common.address'),
      required: true,
    },
    {
      fieldKey: emailFieldKey,
      control: ControlType.TextField,
      type: InputType.Email,
      label: t('contact_method.email'),
      required: true,
    },
    {
      fieldKey: phoneFieldKey,
      control: ControlType.TextField,
      type: InputType.Tel,
      label: t('contact_method.phone'),
      required: true,
    },
  ];
  const creating = !clinic;
  const table = useAComponentPortal(tableKey);

  const submit = async (data: IAddEditClinicFormOutput) => {
    setSubmitting(true);

    try {
      if (!userData?.organisationUid) {
        throw new Error(t('auth.user.error'));
      }
      if (data[addressFieldKey].manualInput && !data[addressFieldKey].detail) {
        message.error(t('patients.add_edit_patient.manual_address_not_saved'));
        return;
      }
      const userTimestamp = getActionTimestampFromUser(userData);
      const basePayload = {
        updated: userTimestamp,
        name: data[nameFieldKey],
        address: data[addressFieldKey].detail!,
        emailAddress: data[emailFieldKey],
        phoneNumber: data[phoneFieldKey],
      };
      if (creating) {
        await ClinicApiService.set({
          uid: uuidv4(),
          created: userTimestamp,
          organisationUid: userData.organisationUid,
          deleted: false,
          ...basePayload,
        });
        message.success(t('dialog.add_clinic.create.success.description'));
      } else {
        await ClinicApiService.update(clinic.uid, {
          ...basePayload,
        });
        message.success(t('dialog.add_clinic.edit.success.description'));
      }
      dialog?.closeDialog();
      table?.exposedFunction();
    } catch (error) {
      message.error(
        t(creating ? 'dialog.add_clinic.create.error.description' : 'dialog.add_clinic.edit.error.description')
      );
      sentryCaptureException(error, creating ? 'Create clinic' : 'Edit clinic', userData);
    } finally {
      setSubmitting(false);
    }
  };

  const customContent = () => {
    return (
      <SharedForm<IAddEditClinicFormOutput>
        onFinish={submit}
        fields={AddEditClinicDialogFields}
        submitting={submitting}
        cancelButton={{
          onClick: () => dialog?.closeDialog(),
          appearance: 'text',
          labelKey: 'common.cancel',
        }}
        name='add-edit-clinic-form'
        existingValue={clinic}
      />
    );
  };

  return (
    <SharedDialogBase
      title={t(creating ? 'dialog.add_edit_clinic.create.title' : 'dialog.add_edit_clinic.edit.title')}
      customContentTemplate={customContent()}
      showButtons={false}
    />
  );
};

export default AddEditClinicDialog;
